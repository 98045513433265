import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { useAcknowledgePageStyles } from './AcknowledgePage.styles';
import Section from './Section';
import { useData } from './AcknowledgePage.hooks';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import { formatSsn } from '../../../../../tools/formatters';
import { useFslSnackbar } from '../../../../../hooks/useFslSnackbar';
import GeneralErrorSnackbar from '../../../../../components/snackbars/GeneralErrorSnackbar';

interface IAcknowledgeProps {
  onNext: () => void;
  onPrevious: () => void;
}

const AcknowledgePage: FunctionComponent<IAcknowledgeProps> = ({ onNext, onPrevious }) => {
  const { translateString } = useTranslation();
  const classes = useAcknowledgePageStyles();
  const { co, fullName, postalCodeAndCity, ssn, address, municipality, country, email, phoneNumber, employmentText, positions, postRequest } = useData(onNext);
  const { failureSnackbar } = useFslSnackbar();

  const handleOnNext = useCallback(async () => {
    const result = await postRequest();
    if (!result) {
      failureSnackbar(<GeneralErrorSnackbar />);
    }
  }, [postRequest, failureSnackbar]);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Section title={translateString('employment')} texts={[employmentText]} />
          <Section
            title={translateString('your_information')}
            texts={[formatSsn(ssn), fullName, '', address, co, postalCodeAndCity, municipality, country, '', email, phoneNumber]}
          />
          <Section title={translateString('terms_of_employment')} texts={positions} />
        </Grid>
      </div>
      <ButtonGroup stepperProgress={3} next={handleOnNext} previous={onPrevious} />
    </>
  );
};

export default AcknowledgePage;
