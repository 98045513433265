import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCasesTileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.white,
      color: theme.customPalette.darkBlue,
    },
    icon: {
      paddingTop: '5px',
      marginBottom: '-25px',
      '& svg': {
        fill: '#EA6073',
      },
    },
  })
);
