import React, { FunctionComponent } from 'react';
import { useDesktopNavbarStyles } from './DesktopNavbar.styles';
import Logo from '../items/logo';
import ListMenu from './ListMenu';

const DesktopNavbar: FunctionComponent = () => {
  const classes = useDesktopNavbarStyles();
  return (
    <div className={classes.root}>
      <nav>
        <Logo renderedOnDesktop />
        <ListMenu />
      </nav>
    </div>
  );
};

export default DesktopNavbar;
