import { Grid, Slide } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { TileInformationApiResponseData } from '../../../api/responseTypes';
import { useFSLQuery } from '../../../hooks/useFslQueryHook';
import { useTileInformationEndpoint } from '../../../hooks/useTileInformationEndpoint';
import ContentSwitch from '../../ContentSwitch/ContentSwitch';
import ListMenu from '../DesktopNavbar/ListMenu';
import Logo from '../items/logo';
import { useMapping } from './Dashboard.hooks';
import CircleTile from './items/CircleTile/CircleTile';
import MembershipTile from './items/MembershipTile/MembershipTile';
import MessageTile from './items/MessageTile/MessageTile';
import SchoolTile from './items/SchoolTile/SchoolTile';
import WelcomeTile from './items/WelcomeTile/WelcomeTile';
import { useMobileDeviceNavbarStyles } from './MobileDeviceNavbar.styles';
import useUser from '../../../auth/UserService';
import CasesTile from './items/CasesTile/CasesTile';

export const MobileHomeComponent = () => {
  const classes = useMobileDeviceNavbarStyles();
  const { fetchTileInformation } = useTileInformationEndpoint();
  const { data } = useFSLQuery<TileInformationApiResponseData>('tileInformation', fetchTileInformation);

  const { myMembershipData, mySchoolData, myDistrictData } = useMapping(data);
  const user = useUser();
  const isExternal = user && !user?.isInternalUser;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={12}>
          <WelcomeTile fullName={`${data?.firstName} ${data?.lastName}`} memberNumber={data?.memberNumber} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {isExternal ? (
          <>
            <Grid item sm={6}>
              <Grid item sm={12}>
                <MembershipTile data={myMembershipData} />
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <SchoolTile data={mySchoolData} />
                </Grid>
                <Grid item sm={6}>
                  <CircleTile data={myDistrictData} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <MessageTile />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={6}>
              <CircleTile />
            </Grid>
            <Grid item sm={6}>
              <MessageTile />
            </Grid>
            <Grid item sm={6}>
              <CasesTile />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

const MobileDeviceNavbar: FunctionComponent = () => {
  const [subMenuOpened, setSubMenuOpened] = useState<boolean>(false);

  const classes = useMobileDeviceNavbarStyles();

  return (
    <div className={classes.root}>
      <nav>
        <Logo renderedOnDesktop={false} subMenuOpened={subMenuOpened} onBurgerClicked={() => setSubMenuOpened((prev) => !prev)} />
      </nav>
      {subMenuOpened ? (
        <Slide in direction="left" timeout={200}>
          <div>
            <ListMenu menuItemClicked={() => setSubMenuOpened(false)} />
          </div>
        </Slide>
      ) : (
        <ContentSwitch homeComponent={MobileHomeComponent} />
      )}
    </div>
  );
};

export default MobileDeviceNavbar;
