import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useFslCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkedRedVariant: {
      '& g': {
        stroke: theme.customPalette.white,
      },
      '& :first-child g:first-child rect:first-child': {
        stroke: theme.customPalette.white,
      },
      '& :first-child g:first-child rect': {
        stroke: theme.customPalette.red,
      },
      '& :first-child g:first-child': {
        fill: theme.customPalette.red,
      },
    },
    uncheckedRedVariant: {
      '& g': {
        stroke: theme.customPalette.red,
      },
      '& :first-child g:first-child': {
        stroke: theme.customPalette.white,
      },
    },
    formControlLabel: {
      marginLeft: 0,
      marginRight: 0,
    },
  })
);
