import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useContentStyles = makeStyles<Theme, { windowHeight: number; showTitleOnMobile?: boolean }>((theme: Theme) => {
  const contentMargin = 3 * theme.spacing();
  return createStyles({
    root: {
      width: 'calc(100% - 389px)',
      margin: `50px ${contentMargin}px 0 ${contentMargin}px`,
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(),
        width: 'auto',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleSection: {
      paddingLeft: theme.spacing(),
      marginBottom: '20px',
      '& h1': {
        margin: '0 0 8px 0',
      },
      '& h2': {
        margin: '0',
      },
      [theme.breakpoints.down('xs')]: {
        display: (props) => `${props.showTitleOnMobile ? 'inline-block' : 'none'}`,
        '& h1': {
          fontSize: '16px',
        },
      },
    },
    rightCornerComponent: {
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        width: (props) => `${props.showTitleOnMobile ? 'auto' : '100%'}`,
      },
    },
    spinner: {
      height: (props) => `${props.windowHeight - 120}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
});
