import React, { FunctionComponent, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePanelStyles } from './Panel.styles';

interface IPanelProps {
  title?: string;
  showAccordion?: boolean;
  edit?: ReactNode;
  sendMessageComponent?: JSX.Element;
}

const Panel: FunctionComponent<IPanelProps> = ({ children, title, showAccordion, edit, sendMessageComponent }) => {
  const [expanded, setExpanded] = useState(true);

  const classes = usePanelStyles();

  return (
    <div className={clsx(classes.root, showAccordion ? classes.accordionRoot : undefined)}>
      {showAccordion ? (
        <Accordion expanded={expanded} onChange={() => setExpanded((state) => !state)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}>
            <div className={classes.accordionBar}>
              <h5 className={clsx(classes.title, classes.accordionTitle)}>{title}</h5>
              {sendMessageComponent || undefined}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Content>{children}</Content>
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <div className={classes.header}>
            {title ? <h5 className={classes.title}>{title}</h5> : null}
            {edit}
          </div>
          <Content>{children}</Content>
        </>
      )}
    </div>
  );
};

const Content: FunctionComponent = ({ children }) => {
  return (
    <Grid container spacing={3}>
      {children}
    </Grid>
  );
};

export default Panel;
