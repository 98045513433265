import React, { FunctionComponent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { useMenuItemStyles } from './MenuItem.styles';

interface IMenuItemProps {
  linkTo: string;
  icon: ReactNode;
  title: string;
  counter?: number | undefined;
  menuItemClicked?: () => void;
  isActive?: (match: any, location: { pathname: string }) => boolean;
}

const MenuItem: FunctionComponent<IMenuItemProps> = ({ linkTo, icon, title, counter, menuItemClicked, isActive }) => {
  const classes = useMenuItemStyles();
  return (
    <NavLink to={linkTo} className={classes.link} exact onClick={menuItemClicked} isActive={isActive}>
      <div className={classes.root}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.title}>
          {title}
          {counter ? <div className={classes.counter}>{counter}</div> : null}
        </div>
      </div>
    </NavLink>
  );
};

export default MenuItem;
