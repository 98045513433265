import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useSimpleSelectStyle = makeStyles((theme: Theme) =>
  createStyles({
    nativeSelect: {
      height: '30px',
      '& .MuiNativeSelect-select:focus': { backgroundColor: 'transparent' },
      '& svg': {
        width: '10px',
        height: '10px',
        paddingRight: '10px',
        paddingTop: '7px',
        '& path': {
          stroke: theme.customPalette.red,
          strokeWidth: 3,
        },
      },
    },
    helperText: {
      color: theme.customPalette.red,
      margin: '0',
      fontSize: '0.75rem',
      marginTop: '3px',
      textAlign: 'left',
      lineHeight: '1.66',
      letterSpacing: '0.03333em',
    },
  })
);
