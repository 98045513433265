import React, { FunctionComponent } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { Typography } from '@material-ui/core';

const GeneralErrorSnackbar: FunctionComponent = () => {
  const { translateString } = useTranslation();
  return (
    <div>
      <div>
        <Typography variant="body1">{translateString('general_error_message_edit_1')}</Typography>
      </div>
      <div>
        <Typography variant="body1">{translateString('general_error_message_edit_2')}</Typography>
      </div>
    </div>
  );
};

export default GeneralErrorSnackbar;
