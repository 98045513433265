import React, { useState } from 'react';
import { ModalInfo } from '../../components/Modal/Modal.types';

export const ModalContext = React.createContext<{
  modalState: ModalInfo | null;
  setModalState: React.Dispatch<React.SetStateAction<ModalInfo | null>>;
  toggleOpen: () => void;
}>({
  modalState: null,
  setModalState: () => {
    return null;
  },
  toggleOpen: () => {},
});

export const ModalProvider: React.FunctionComponent = ({ children }) => {
  const [modalState, setModalState] = useState<ModalInfo | null>({ open: false, options: null, title: null, props: null, componentConstructor: null });
  return (
    <ModalContext.Provider
      value={{
        modalState,
        setModalState,
        toggleOpen: () => {
          setModalState((prev) => {
            return {
              open: !prev?.open,
              componentConstructor: prev?.componentConstructor,
              props: prev?.props,
              options: prev?.options,
              title: prev?.title,
            } as ModalInfo;
          });
        },
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
