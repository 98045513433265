import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useMySubscriptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    sidePanel: {
      width: '148px',
      backgroundColor: theme.customPalette.lightGrey,
      padding: '20px',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
        width: 'calc(100% - 40px)',
      },
    },
    sidePanelHeader: {
      fontWeight: theme.fontWeight,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    sidePanelValueHeader: {
      fontWeight: theme.fontWeight,
    },
    sidePanelValue: {
      wordBreak: 'break-word',
      fontWeight: theme.fontWeight,
    },
    sidePanelValuePair: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing() * 2,
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing() * 2,
      },
    },
    sidePanelValueIsNegative: {
      color: theme.customPalette.red,
    },
    tableContainer: {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        paddingLeft: '42.5px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: '20px',
      },
    },
    firstColumnContent: {
      display: 'flex',
      textTransform: 'capitalize',
      '& > :first-child': {
        paddingRight: '10px',
      },
    },
  })
);
