import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useContext } from 'react';
import { ApplicationInsightsContext } from './ApplicationInsightsContext';

export function useApplicationInsights() {
  return useContext(ApplicationInsightsContext)?.appInsights ?? new ApplicationInsights({ config: { instrumentationKey: 'dummy', disableTelemetry: true } });
}

export function useApplicationInsightsReactPlugin() {
  return useContext(ApplicationInsightsContext)?.reactPlugin ?? new ReactPlugin();
}
