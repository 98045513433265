import { useQuery, QueryConfig, queryCache, MutationConfig, useMutation } from 'react-query';
import { isAfter } from 'date-fns';

export type QuerykeyString =
  | 'messages'
  | 'message'
  | 'thread'
  | 'sender'
  | 'numberOfReadMessages'
  | 'membership'
  | 'sentMessages'
  | 'districts'
  | 'school'
  | 'validateEmail'
  | 'employment'
  | 'positionsOfTrust'
  | 'schoolAssociations'
  | 'tileInformation'
  | 'courses'
  | 'cases';

export const useFSLQuery = <T extends any>(
  key: QuerykeyString | any[],
  fetchFunction: () => void,
  queryConfig?: Omit<QueryConfig<any>, 'refetchOnWindowFocus' | 'retry'>
) => {
  const { data, error, status, isLoading, refetch } = useQuery(key, fetchFunction, {
    refetchOnWindowFocus: false,
    retry: 0,
    ...(queryConfig || {}),
  });

  return { data: data as T, error, status, isLoading, refetch };
};

export const useFSLMutation = <T extends any, K extends { lastUpdated?: string }>(
  key: QuerykeyString | any[],
  mutateFn: (data: T) => Promise<T>,
  updateCache: (newData: T, previousValue: K | undefined) => K,
  mutationConfig?: Omit<MutationConfig<T, K | undefined, T, K | undefined>, 'onMutate' | 'onSettled' | 'onError'>,
  onError?: () => void
) => {
  const previousValue = queryCache.getQueryData<K>(key);

  const [mutate] = useMutation<T, K | undefined, T, K | undefined>(mutateFn, {
    onMutate: () => {
      queryCache.cancelQueries(key);

      return previousValue;
    },
    onSettled: async () => {
      const previousLastUpdated = previousValue?.lastUpdated;
      if (previousLastUpdated) {
        let i = 0;
        let hasBeenUpdated = false;

        const delay = () => new Promise((res) => setTimeout(res, 500));

        while (i < 10 && !hasBeenUpdated) {
          i++;

          const data = queryCache.getQueryData<K>(key);
          const lastUpdated = data?.lastUpdated;

          if (lastUpdated) {
            if (isAfter(new Date(lastUpdated), new Date(previousLastUpdated))) {
              hasBeenUpdated = true;
            } else {
              queryCache.invalidateQueries(key);
              await delay();
            }
          } else {
            // no lastupdated ? Bailing out
            hasBeenUpdated = true;
            queryCache.invalidateQueries(key);
          }
        }
      } else {
        queryCache.invalidateQueries(key);
      }
    },
    onError: (error, newData, snapshotValue) => {
      if (snapshotValue) {
        queryCache.setQueryData<K>(key, snapshotValue);
      }
      onError?.();
    },
    ...mutationConfig,
  });

  return { mutate };
};
