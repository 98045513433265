import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useNewMembershipPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sidepanel: {
      paddingTop: '29px',
      paddingLeft: '34px',
      width: '310px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        '& svg': {
          width: '56px',
          height: '56px',
        },
      },
    },
  })
);
