import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useLabelValueComponentStyles = makeStyles<Theme, { highlightColor: string; dotColor: string }>((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: theme.fontWeight,
      marginBottom: '5px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    value: {
      wordBreak: 'break-word',
      '& a': {
        color: theme.customPalette.red,
        fontWeight: theme.fontWeight,
      },
    },
    highlight: {
      color: (props) => props.highlightColor,
      fontWeight: theme.fontWeight,
    },
    dot: {
      '&::before': {
        content: '""',
        display: 'inline-block',
        height: '12px',
        width: '12px',
        backgroundColor: (props) => props.dotColor,
        borderRadius: '20px',
        marginRight: '10px',
      },
    },
  })
);
