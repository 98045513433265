import { useTranslation, useValidator } from '@fsl/fsl.common.npm';
import { useCallback, useContext, useMemo } from 'react';
import { validatorMessageTranslate } from '../../../../../tools/formatters';
import { NewMemberContext } from '../../../newMemberContext';
import { EditableEmploymentData } from './EmploymentPage.types';

export const useData = () => {
  const { translateString } = useTranslation();
  const { state, dispatch } = useContext(NewMemberContext);

  const validator = useValidator<EditableEmploymentData>((v) => ({
    employment: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'employment' }), (t) => t.employmentId),
    employmentText: (t) => {
      if (t.employmentId === 0 && (t.employmentText === undefined || t.employmentText === '')) {
        return `${translateString('cannot_be_empty', { field: translateString('employment_sitation') })}`;
      }
      return undefined;
    },
  }));

  const options = useMemo(
    () => [
      { id: -1, displayValue: '' },
      { id: 1, displayValue: translateString('in_work') },
      { id: 4, displayValue: translateString('studying') },
      { id: 0, displayValue: translateString('other') },
    ],
    [translateString]
  );

  const employmentChanged = useCallback(
    (id: string) => {
      if (id) {
        const employment = options.find((o) => o.id === Number(id));
        validator.showErrorFor('employment');
        if (employment) {
          dispatch({ type: 'setEmployment', employment: { employment: employment.displayValue, employmentId: employment.id } });
        }
      }
    },
    [options, dispatch, validator]
  );

  const selectedDisplayValue = useMemo(() => options.find((o) => o.displayValue === state.employment?.employment)?.displayValue, [options, state.employment]);

  const showTextArea = useMemo(() => state.employment?.employment === translateString('other'), [state.employment, translateString]);

  const employmentTextChanged = useCallback(
    (employmentText: string) => {
      validator.showErrorFor('employmentText');
      dispatch({ type: 'setEmployment', employment: { employmentText: employmentText ?? '' } });
    },
    [dispatch, validator]
  );

  const data = useMemo(() => {
    return { employment: state.employment?.employmentId, employmentText: state.employment?.employmentText };
  }, [state.employment]);

  const isEulaAccepted = useMemo(() => {
    return state.acceptedTermsAndConditions !== undefined;
  }, [state.acceptedTermsAndConditions]);

  const acceptEula = useCallback(
    (eulaName: string | undefined) => {
      if (eulaName) {
        dispatch({ type: 'setEulaName', eulaName });
      }
    },
    [dispatch]
  );

  validator.check({ employment: selectedDisplayValue, employmentText: data.employmentText, employmentId: data.employment });

  return {
    data,
    options,
    employmentChanged,
    showTextArea,
    employmentTextChanged,
    validator,
    isEulaAccepted,
    acceptEula,
  };
};
