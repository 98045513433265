import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NewMemberContext } from '../../../newMemberContext';

export const useData = (onNext: () => void) => {
  const { state, dispatch } = useContext(NewMemberContext);
  const [showErrors, setShowErrors] = useState(false);
  const errors = useRef<boolean[]>([true]);

  useEffect(() => {
    dispatch({ type: 'setBypassPosition', value: false });
  }, [dispatch]);

  const next = useCallback(
    ({ bypassValidation }: { bypassValidation: boolean }) =>
      () => {
        if (bypassValidation) {
          dispatch({ type: 'setBypassPosition', value: true });
          onNext();
        } else if (errors.current.every((err) => !err)) {
          onNext();
        } else {
          setShowErrors(true);
        }
      },
    [onNext, dispatch]
  );

  useEffect(() => {
    if (showErrors) {
      setShowErrors(false);
    }
  }, [showErrors]);

  const addPosition = useCallback(() => {
    dispatch({ type: 'updatePositions', position: {}, index: state.positions.length });
  }, [dispatch, state.positions]);

  const removePosition = useCallback(
    (index: number) => {
      errors.current.splice(index, 1);
      dispatch({ type: 'removePosition', index });
    },
    [dispatch]
  );

  return { positions: state.positions, addPosition, removePosition, next, showErrors, errors };
};
