import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';
import { useLabelValueComponentStyles } from './LabelValueComponent.styles';
import FadeComponent from '../FadeComponent/FadeComponent';

interface ILabelValueComponentProps {
  label: string;
  value: string | string[] | JSX.Element;
  highlight?: { valueColor: string };
  showDot?: { circleColor: string };
}

const LabelValueComponent: FunctionComponent<ILabelValueComponentProps> = ({ label, value, highlight, showDot }) => {
  const classes = useLabelValueComponentStyles({
    highlightColor: highlight ? highlight.valueColor : '#000',
    dotColor: showDot ? showDot.circleColor : 'transparent',
  });

  const displayValue = useMemo(() => {
    const valueClassNames = clsx(classes.value, `${highlight ? classes.highlight : ''} ${showDot ? classes.dot : ''}`);
    if (Array.isArray(value)) {
      return (
        <>
          {value.map((v, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} style={{ marginBottom: '5px' }}>
              <Typography variant="body1" className={valueClassNames}>
                {v}
              </Typography>
            </div>
          ))}
        </>
      );
    }

    return (
      <Typography variant="body1" className={valueClassNames}>
        {value}
      </Typography>
    );
  }, [value, classes.dot, classes.highlight, highlight, showDot, classes.value]);

  return (
    <FadeComponent>
      <div>
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
        {displayValue}
      </div>
    </FadeComponent>
  );
};

export default LabelValueComponent;
