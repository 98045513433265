import { AutocompleteCloseReason } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import SelectWithSearch from '../../input/SelectWithSearch/SelectWithSearch';
import { useData } from './SchoolPicker.hooks';
import { ISchoolDropDownItem } from './SchoolPicker.types';

interface ISchoolPickerProps {
  onChange?: (changes: ISchoolDropDownItem | undefined) => void;
  error?: boolean;
  helperText?: string;
  title?: string;
  fetchOptions: (query: string) => Promise<ISchoolDropDownItem[]>;
  value: ISchoolDropDownItem | undefined;
  disabled?: boolean;
  autoFocus?: boolean;
}

const SchoolPicker: FunctionComponent<ISchoolPickerProps> = ({ onChange, error, helperText, title, fetchOptions, value, disabled, autoFocus }) => {
  const { input, handleOnInputChanged, isOpen, setIsOpen, options, selectedOption, handleOnChange } = useData(value, fetchOptions, onChange);

  return (
    <SelectWithSearch<ISchoolDropDownItem>
      autoFocus={autoFocus}
      value={selectedOption}
      title={title}
      error={error}
      helperText={helperText}
      onChange={handleOnChange}
      options={options}
      inputValue={input}
      onInputChange={handleOnInputChanged}
      updateDelay={50}
      open={isOpen && !disabled}
      onOpen={() => setIsOpen(true)}
      filterOptions={(x) => x}
      getOptionLabel={(option) => `${option.name}${option.schoolType ? ` (${option.schoolType})` : ''} `}
      getOptionSelected={(l, r) => l.id === r.id}
      onClose={(_, reason: AutocompleteCloseReason) => {
        if (reason === 'toggleInput') {
          setIsOpen(false);
        }
      }}
      isDisabled={disabled}
    />
  );
};

export default SchoolPicker;
