import { CircularProgress, InputAdornment, Popper } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React from 'react';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  Value,
} from '@material-ui/lab';
import dropdownIcon from '../../../../images/dropdown.svg';
import { toArray } from '../../../../tools/utilities';
import BasicTextField from '../BasicTextField/BasicTextField';
import FslTextField from '../FslTextField/FslTextField';

function defaultGetOptionSelected(option: any, value: any) {
  if (typeof option !== typeof value) return false;
  if (option === value) return true;

  if (typeof option !== 'object') return false;

  return Object.keys(option)
    .concat(Object.keys(value))
    .map((k) => option[k] === value[k])
    .every((v) => v);
}

export interface ISelectWithSearchProps<T, Multiple extends boolean = false> {
  title?: string;
  placeholder?: string;
  className?: string;
  iconPosition?: 'start' | 'end';
  value?: Value<T, Multiple, false, false>;
  inputValue?: string;
  options?: T[];
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<T, Multiple, false, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  onInputChange?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  getOptionLabel?: (option: T) => string;
  renderOption?: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
  inputFieldId?: string;
  isLoading?: boolean;
  noOptionsText?: string;
  isClearable?: boolean;
  clearOnBlur?: boolean;
  getOptionSelected?: (option: T, value: T) => boolean;
  popupIcon?: HTMLImageElement;
  hideIcon?: boolean;
  hidePopupIcon?: boolean;
  inputAdornmentIcon?: HTMLImageElement;
  autoFocus?: boolean;
  isDisabled?: boolean;
  helperText?: string;
  error?: boolean;
  isReadOnly?: boolean;
  required?: boolean;
  multiple?: Multiple;
  blurOnSelect?: boolean;
  open?: boolean;
  onOpen?: (event: React.ChangeEvent<{}>) => void;
  onClose?: (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => void;
  updateDelay?: number;
}

export default function SelectWithSearch<T, Multiple extends boolean = false>({
  iconPosition,
  inputFieldId,
  title,
  placeholder,
  className,
  value,
  options,
  onChange,
  renderOption,
  getOptionLabel,
  onInputChange,
  inputValue,
  filterOptions,
  isLoading,
  noOptionsText,
  isClearable,
  clearOnBlur,
  getOptionSelected,
  popupIcon,
  hideIcon,
  inputAdornmentIcon,
  hidePopupIcon,
  autoFocus,
  isDisabled,
  helperText,
  error,
  isReadOnly,
  required,
  multiple,
  blurOnSelect,
  open,
  onClose,
  onOpen,
  updateDelay,
}: ISelectWithSearchProps<T, Multiple>) {
  if (isReadOnly && !multiple) {
    return (
      <BasicTextField
        id={inputFieldId}
        label={title}
        className={className}
        autoFocus={autoFocus}
        disabled={isDisabled}
        error={error}
        helperText={helperText}
        isReadOnly={isReadOnly}
        value={(value ? getOptionLabel?.(value as T) : value) ?? null}
        required={required}
      />
    );
  }

  return (
    <Autocomplete<T, Multiple, boolean, false>
      multiple={multiple}
      style={{ width: '100%' }}
      value={value ?? null!}
      disabled={isDisabled}
      placeholder={title}
      options={options ?? (toArray(value) as T[])}
      filterOptions={filterOptions}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      clearOnBlur={clearOnBlur}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      loading={isLoading}
      loadingText="Henter..."
      disableClearable={!isClearable}
      noOptionsText={noOptionsText ?? (!inputValue ? 'Foretag søgning' : 'Ingen resultater')}
      getOptionSelected={getOptionSelected ?? defaultGetOptionSelected}
      popupIcon={!hidePopupIcon ? popupIcon ?? <img src={dropdownIcon} alt="dropdownButton" style={{ width: '15px', height: '15px' }} /> : null}
      blurOnSelect={blurOnSelect}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <FslTextField
          {...params}
          placeholder={placeholder}
          id={inputFieldId}
          label={title}
          className={className}
          style={{ minHeight: '32px' }}
          autoFocus={autoFocus}
          disabled={isDisabled}
          error={error}
          helperText={helperText}
          updateDelay={updateDelay ?? 0}
          InputProps={{
            ...params.InputProps,
            startAdornment: !hideIcon ? (
              <>
                {params.InputProps.startAdornment}
                <InputAdornment position={iconPosition ?? 'start'}>{popupIcon ? inputAdornmentIcon : <Search />}</InputAdornment>
              </>
            ) : (
              params.InputProps.startAdornment
            ),
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      PopperComponent={(props) => {
        return <Popper {...props} style={{ minWidth: props.style?.width, ...(props.style ?? {}), width: 'auto' }} />;
      }}
    />
  );
}

SelectWithSearch.defaultProps = {
  title: '',
};
