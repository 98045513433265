import { useTranslation } from '@fsl/fsl.common.npm';
import React, { FunctionComponent } from 'react';
import { userManager } from '../../auth';

const FslAutoRedirectPage: FunctionComponent = () => {
  const { translateString } = useTranslation();
  const redirectUri = `${window.location.protocol}//${window.location.host}/fslcallback`;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const messageMemberId = urlSearchParams.get('messageMemberId');

  if (messageMemberId) {
    userManager.signinRedirect({ redirect_uri: redirectUri, state: `${messageMemberId}` });
  } else {
    userManager.signinRedirect({ redirect_uri: redirectUri });
  }

  return (
    <>
      <h1>{translateString('fsl_identity_redirect')}</h1>
    </>
  );
};

export default FslAutoRedirectPage;
