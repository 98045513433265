import { useCallback } from 'react';
import { get } from '../api';

export const useJobTitlesEndpoint = () => {
  const endpoint = '/jobtitles';

  const fetchJobTitles = useCallback(async (): Promise<{ id: string; title: string }[]> => {
    const response = await get(endpoint, { ignoreAuthentication: true });
    const json = await response.json();

    return json;
  }, []);

  return { endpoint, fetchJobTitles };
};
