import React, { FunctionComponent } from 'react';
import { useMailValidationStyles } from './MailValidationPage.styles';
import { useData } from './MailValidation.hooks';

const MailValidationPage: FunctionComponent = () => {
  const classes = useMailValidationStyles();

  const { content } = useData();

  return (
    <div className={classes.root}>
      <div className={classes.centerContent}>
        <div className={classes.content}>{content}</div>
      </div>
    </div>
  );
};

export default MailValidationPage;
