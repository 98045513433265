import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

type IBasicTextFieldProps = TextFieldProps & { isReadOnly?: boolean } & ({ value?: string | null } | { defaultValue?: string });

function BasicTextField({ helperText, isReadOnly, InputProps, value, defaultValue, ...props }: IBasicTextFieldProps, ref: React.Ref<HTMLDivElement>) {
  if (defaultValue !== undefined && defaultValue !== null) {
    value = undefined;
  } else {
    value = value ?? '';
  }

  return (
    <TextField
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      style={{ width: '100%' }}
      helperText={helperText || ' '}
      {...props}
      InputProps={{
        readOnly: isReadOnly,
        ...(InputProps ?? {}),
      }}
    />
  );
}

export default React.forwardRef(BasicTextField);
