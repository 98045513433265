import React, { createContext, FunctionComponent, useCallback, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContextState, MessageContextType, Recipient, ThreadMessageViewModel, ThreadViewModel } from './MessageContext.types';
import reducer from './MessageContext.reducer';
import Routing from '../../routing/routing';
import { useMessagesEndpoint } from '../../hooks/useMessagesEndpoint';
import { useFSLQuery } from '../../hooks/useFslQueryHook';

const initialState: MessageContextState = {
  recipients: [],
  topic: '',
  currentDraftMessage: '',
  allowedSenders: [],
  parentId: undefined,
  numberOfUnreadMessages: 0,
  selectedThread: undefined,
  sender: undefined,
};

export const MessageContext = createContext<MessageContextType>({
  state: initialState,
  openNewMessage: () => null,
  openReplyMessage: () => null,
  setRecipients: () => null,
  updateTopic: () => null,
  setDraftMessage: () => null,
  updateSelectedThread: () => null,
  setMessageExpanded: () => null,
  updateMessages: () => null,
});

export const MessageProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { push } = useHistory();
  const isInitializing = useRef(true);
  const { fetchSenders, fetchNumberOfReadMessages } = useMessagesEndpoint();

  useFSLQuery('sender', fetchSenders, { onSuccess: (data) => dispatch({ type: 'setAllowedSenders', senders: data }) });
  useFSLQuery('numberOfReadMessages', fetchNumberOfReadMessages, {
    onSuccess: (data) => dispatch({ type: 'setNumberOfUnreadMessages', numberOfUnreadMessages: data?.count ?? 0 }),
  });

  const updateStateOnNewMessage = useCallback(
    (positionOfTrustMessage: boolean, recipients: Recipient[], parentId: string | undefined, sender: Recipient | undefined) => {
      dispatch({ type: 'setRecipients', recipients: recipients ?? [] });
      dispatch({ type: 'setTopic', topic: '' });
      dispatch({ type: 'setParentId', parentId });
      dispatch({ type: 'setSender', sender });
    },
    []
  );

  const openReplyMessage = useCallback(
    (parentId: string) => {
      updateStateOnNewMessage(false, [], parentId, undefined);
      push(Routing.reply);
    },
    [push, updateStateOnNewMessage]
  );

  const openNewMessage = useCallback(
    (positionOfTrustMessage: boolean, recipients: Recipient[], sender: Recipient | undefined) => {
      updateStateOnNewMessage(positionOfTrustMessage, recipients ?? [], undefined, sender);
      push(Routing.newMessage);
    },
    [push, updateStateOnNewMessage]
  );

  const setRecipients = useCallback((recipients: Recipient[]) => {
    dispatch({ type: 'setRecipients', recipients });
  }, []);

  const updateTopic = useCallback((topic: string) => {
    dispatch({ type: 'setTopic', topic });
  }, []);

  const setDraftMessage = useCallback((draftMessage: string) => {
    dispatch({ type: 'setDraftMessage', draftMessage });
  }, []);

  const updateSelectedThread = useCallback((thread: ThreadViewModel | undefined) => {
    dispatch({ type: 'setSelectedThread', thread });
  }, []);

  const setMessageExpanded = useCallback((messageId: string, isExpanded: boolean) => {
    dispatch({ type: 'setIsMessageExpanded', messageId, isExpanded });
  }, []);

  const updateMessages = useCallback((message: ThreadMessageViewModel) => {
    dispatch({ type: 'updateMessages', message });
  }, []);

  isInitializing.current = false;

  return (
    <MessageContext.Provider
      value={{
        state,
        openNewMessage,
        openReplyMessage,
        setRecipients,
        updateTopic,
        setDraftMessage,
        updateSelectedThread,
        setMessageExpanded,
        updateMessages,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
