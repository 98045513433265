import { MyDistrictData, MyMembershipData, MySchoolData } from './Dashboard.types';
import { TileInformationApiResponseData } from '../../../api/responseTypes';

/*
The balance needs its sign flipped for ui purposes
The balance is saved as a positive number in the database when the member hasn't paid
But it needs to be shown as a negative number
Flipping "balance" as well as the "negativeRate" to match
 */
export const mapToMyMembershipData = (data: TileInformationApiResponseData): MyMembershipData | undefined => {
  if (data) {
    return {
      membernumber: data.memberNumber ? `${data.memberNumber}` : '',
      balance: setBalance(data.balance),
      memberStatus: data.memberEconomyStatus === 'Exempt' ? data.memberEconomyStatus : data.memberStatus,
      memberType: data.memberType,
      positions: [
        ...(data.schoolMemberTrustPositions?.map((p) => `${p.positionOfTrustName} - ${p.schoolName}`) ?? []),
        ...(data.districtMemberTrustPositions?.map((p) => `${p.positionOfTrustName} - ${p.districtName}`) ?? []),
      ],
      negativeRate: data.balance ? -data.balance < 0 : false,
    };
  }
  return undefined;
};

export const mapToMySchoolData = (data: TileInformationApiResponseData): MySchoolData | undefined => {
  if (data) {
    return {
      schoolName: data.schoolName,
      schoolEmail: data.schoolEmail,
    };
  }

  return undefined;
};

export const mapToMyDistrictData = (data: TileInformationApiResponseData): MyDistrictData | undefined => {
  if (data) {
    return {
      memberTrustPositionsCount: data.memberTrustPositionsCount,
      districtNumber: data.districtNumber,
    };
  }
  return undefined;
};

const setBalance = (balance: number | null | undefined) => {
  if (typeof balance === 'number') {
    return balance > 0 ? -balance : balance;
  }

  return undefined;
};
