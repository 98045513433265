import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useMailValidationStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '600px',
      height: '600px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '15px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '20px',
        paddingLeft: '39.5px',
        paddingRight: '28.5px',
      },
    },
    centerContent: {
      width: '500px',
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& :first-child': {
        marginBottom: '5px',
      },
    },
  })
);
