import { stringify } from 'query-string';
import { DawaQueryOptions } from '../components/user-input/picker/AdressPicker/AddressPicker.types';
import { fetchRequest } from './fetch';
import { ApiOptions } from './types';

const dawaBaseUrl = 'https://dawa.aws.dk';

export const get = (path: string, options?: ApiOptions) => {
  return fetchRequest(path, 'GET', undefined, undefined, options);
};

export const put = (data: any, path: string) => {
  return fetchRequest(path, 'PUT', data);
};

export const post = (data: any, path: string, options?: ApiOptions) => {
  return fetchRequest(path, 'POST', data, undefined, options);
};

export const deleteRequest = (path: string, data: any) => {
  return fetchRequest(path, 'DELETE', data);
};

export const getDawa = async (path: string) => {
  try {
    const response = await window.fetch(`${dawaBaseUrl}/${path}`);
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const getDawaAutocompleteAddresses = async (query: DawaQueryOptions) => {
  const queryString = stringify({ ...query, caretpos: query.q?.length } as DawaQueryOptions, { skipEmptyString: false, skipNull: true });
  return getDawa(`autocomplete?${queryString}`);
};

export const getDawaMunicipality = async (municipalityCode: string) => {
  return getDawa(`kommuner/${municipalityCode}`);
};
