import React, { useCallback, useContext, useMemo } from 'react';
import { ModalInfo, ModalOptions, ModalProps } from './Modal.types';
import { ModalContext } from '../../contexts/ModalProvider/ModalProvider';

export function useModal() {
  const { setModalState, modalState } = useContext(ModalContext);

  const openModal = useCallback(
    <TProps>(title: string, componentConstructor: React.ComponentType<TProps>, optionsAndProps?: ModalOptions & { props?: TProps & ModalProps }) => {
      const { props, ...options } = optionsAndProps ?? {};

      setModalState({ title, props, options, componentConstructor, open: true } as ModalInfo);
    },
    [setModalState]
  );

  const toggleModal = useCallback(() => {
    setModalState(
      (prev) =>
        ({ componentConstructor: prev?.componentConstructor, props: prev?.props, title: prev?.title, options: prev?.options, open: !prev?.open } as ModalInfo)
    );
  }, [setModalState]);

  const closeModal = useCallback(() => {
    setModalState((prev) => {
      if (prev) return { ...prev, open: false };
      return null;
    });
  }, [setModalState]);

  const isModalOpen = useMemo(() => {
    return modalState?.open ?? false;
  }, [modalState]);

  return { openModal, toggleModal, isModalOpen, closeModal };
}
