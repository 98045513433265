import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useAcknowledgePageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(),
    },
    header: {
      fontWeight: theme.fontWeight,
    },
    headerGrid: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(),
      },
    },
    textSeparator: {
      height: '25px',
    },
  })
);
