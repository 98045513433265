import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useWelcomeTileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.customPalette.darkBlue,
      color: theme.customPalette.white,
    },
    icon: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: `25px ${theme.spacing()}px 0 0`,
      [theme.breakpoints.up('sm')]: {
        height: '100%',
        margin: `0px 0 0`,
        alignItems: 'flex-end',
      },
    },
    textContainer: {
      position: 'absolute',
      marginTop: theme.spacing(2),
      paddingLeft: '15px',
    },
    header: {
      fontWeight: theme.fontWeight,
      fontSize: '20px',
      color: theme.customPalette.white,
    },
    text: {
      marginTop: '10px',
      fontSize: '14px',
    },
  })
);
