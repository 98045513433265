import { useTranslation } from '@fsl/fsl.common.npm';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import React, { FunctionComponent } from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { ReactComponent as FrontPageIcon } from '../../../images/Forside_menuikon.svg';
import { ReactComponent as CoursePageIcon } from '../../../images/Kursus_menuikon.svg';
import { ReactComponent as LogoutPageIcon } from '../../../images/Logud.svg';
import { ReactComponent as MyCirclePageIcon } from '../../../images/Min kreds_menuikon.svg';
import { ReactComponent as MySchoolPageIcon } from '../../../images/Min skole_menuikon.svg';
import { ReactComponent as MembershipPageIcon } from '../../../images/Mit medlemskab_menuikon.svg';
import Routing from '../../../routing/routing';
import { useDesktopNavbarStyles } from './DesktopNavbar.styles';
import MenuItem from './items/MenuItem/MenuItem';
import { useListMenuData } from './ListMenu.hooks';
import useUser from '../../../auth/UserService';

interface IListMenuProps {
  menuItemClicked?: () => void;
}

const ListItemWrapper: FunctionComponent = ({ children }) => {
  return <li className="listItem">{children}</li>;
};

const ListMenu: FunctionComponent<IListMenuProps> = ({ menuItemClicked }) => {
  const classes = useDesktopNavbarStyles();
  const user = useUser();
  const { translateString } = useTranslation();

  const { isMessagesLinkActive, numberOfUnreadMessages } = useListMenuData();

  return (
    <nav>
      <ul className={classes.menuList}>
        <ListItemWrapper>
          <MenuItem title={translateString('frontpage')} linkTo={Routing.frontpage} icon={<FrontPageIcon />} menuItemClicked={menuItemClicked} />
        </ListItemWrapper>
        {user && !user?.isInternalUser && (
          <ListItemWrapper>
            <MenuItem linkTo={Routing.membership} title={translateString('my_membership')} icon={<MembershipPageIcon />} menuItemClicked={menuItemClicked} />
          </ListItemWrapper>
        )}
        <ListItemWrapper>
          <MenuItem title={translateString('courses')} linkTo={Routing.courses} icon={<CoursePageIcon />} menuItemClicked={menuItemClicked} />
        </ListItemWrapper>
        {user && !user?.isInternalUser && (
          <ListItemWrapper>
            <MenuItem linkTo={Routing.school} title={translateString('my_school')} icon={<MySchoolPageIcon />} menuItemClicked={menuItemClicked} />
          </ListItemWrapper>
        )}

        <ListItemWrapper>
          <MenuItem linkTo={Routing.district} title={translateString('my_district')} icon={<MyCirclePageIcon />} menuItemClicked={menuItemClicked} />
        </ListItemWrapper>
        <MenuItem
          linkTo={Routing.messages}
          title={translateString('my_messages')}
          icon={<CommentOutlinedIcon />}
          counter={numberOfUnreadMessages}
          menuItemClicked={menuItemClicked}
          isActive={isMessagesLinkActive}
        />
        <MenuItem linkTo={Routing.cases} title={translateString('my_cases')} icon={<DescriptionOutlinedIcon />} menuItemClicked={menuItemClicked} />
        <ListItemWrapper />
        <ListItemWrapper>
          <div className={classes.separator} />
        </ListItemWrapper>
        <ListItemWrapper>
          <MenuItem linkTo={Routing.logout} title={translateString('log_out')} icon={<LogoutPageIcon />} />
        </ListItemWrapper>
      </ul>
    </nav>
  );
};

export default ListMenu;
