import { MessageContextAction, MessageContextState } from './MessageContext.types';

export default (state: MessageContextState, action: MessageContextAction): MessageContextState => {
  switch (action.type) {
    case 'setRecipients': {
      return { ...state, recipients: action.recipients };
    }
    case 'setTopic': {
      return { ...state, topic: action.topic };
    }
    case 'setDraftMessage': {
      return { ...state, currentDraftMessage: action.draftMessage };
    }
    case 'setAllowedSenders': {
      return { ...state, allowedSenders: action.senders };
    }
    case 'setParentId': {
      return { ...state, parentId: action.parentId };
    }
    case 'setNumberOfUnreadMessages': {
      return { ...state, numberOfUnreadMessages: action.numberOfUnreadMessages };
    }
    case 'setSelectedThread': {
      return { ...state, selectedThread: action.thread };
    }
    case 'setIsMessageExpanded': {
      const index = state.selectedThread?.messages.findIndex((m) => m.id === action.messageId);

      if (index !== undefined && state.selectedThread) {
        const messages = [...state.selectedThread.messages];
        messages[index].isExpanded = action.isExpanded;

        return { ...state, selectedThread: { ...state.selectedThread, messages } };
      }

      return state;
    }
    case 'updateMessages': {
      if (state.selectedThread) {
        const previewIndex = state.selectedThread?.messages.findIndex((m) => m.id === action.message.id);
        if (previewIndex !== undefined) {
          const messages = [...state.selectedThread.messages];
          messages[previewIndex] = action.message;

          return { ...state, selectedThread: { ...state.selectedThread, messages } };
        }
      }

      return state;
    }
    case 'setSender': {
      return { ...state, sender: action.sender };
    }

    default: {
      return state;
    }
  }
};
