import { useEffect, useState } from 'react';
import { useTermsAndConditionsEndpoint } from '../../../../../../hooks/useTermsAndConditionsEndpoint';

export const useData = (language: string) => {
  const [eula, setEula] = useState<{ name: string; eula: string }>();
  const { fetchPdf } = useTermsAndConditionsEndpoint(language);

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchPdf();
      setEula({ name: response?.name, eula: response?.base64Pdf });
    };

    fetch();
  }, [fetchPdf]);

  return { eula };
};
