import { useCallback } from 'react';
import { get } from '../api';

export const useSchoolNameEndpoint = () => {
  const endpoint = '/schools';

  const fetchSchoolNames = useCallback(async (query: string): Promise<
    {
      id: string;
      name: string;
      address: string;
      postalCode: string;
      city: string;
      schoolType: string;
    }[]
  > => {
    const response = await get(`${endpoint}?Query=${query}`, { ignoreAuthentication: true });
    const json = await response.json();
    return json?.data;
  }, []);

  return { endpoint, fetchSchoolNames };
};
