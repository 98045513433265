import { CircularProgress, Grid } from '@material-ui/core';
import React, { CSSProperties, PropsWithChildren } from 'react';

export function FullScreenCenter({ children }: PropsWithChildren<any>) {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
      {children}
    </Grid>
  );
}

export function FullScreenLoadingComponent() {
  return (
    <FullScreenCenter>
      <CircularProgress />
    </FullScreenCenter>
  );
}

export function CenterContent({ children, style, className }: PropsWithChildren<{ style?: CSSProperties; className?: string }>) {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={style} className={className}>
      {children}
    </Grid>
  );
}

export function VerticallyCenterContent({ children, style, className }: PropsWithChildren<{ style?: CSSProperties; className?: string }>) {
  return (
    <Grid container spacing={0} direction="row" alignItems="center" justify="center" style={style} className={className}>
      {children}
    </Grid>
  );
}
