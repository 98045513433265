import { AuthProvider, useAuth, UserManager } from 'oidc-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FullScreenLoadingComponent } from '../components/misc';
import config from '../config';

export const userManager = new UserManager({
  automaticSilentRenew: true,
  authority: config.authServer,
  client_id: 'fsl_memberportal_frontend',
  redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
  response_type: 'code',
  scope: 'openid profile fsl_memberportal_backend fsl_employments fsl_positions_of_trust fsl_districts fsl_internal_client',
  post_logout_redirect_uri: config.postLogoutUrl ?? `${window.location.protocol}//${window.location.host}`,
});

export const withOidc = (App: React.ComponentType<any>) => {
  function LoaderOrApp() {
    const auth = useAuth();

    if (auth?.userData) {
      return <App />;
    }

    return <FullScreenLoadingComponent />;
  }

  return () => {
    const history = useHistory();

    return (
      <AuthProvider
        userManager={userManager}
        autoSignIn
        onBeforeSignIn={() => {
          sessionStorage.setItem('OnSigninRedirect', document.location.pathname + document.location.search + document.location.hash);
        }}
        onSignIn={(userData) => {
          if (!userData) return;

          const returnTo = sessionStorage.getItem('OnSigninRedirect');
          if (returnTo) {
            history.push(returnTo);
            sessionStorage.removeItem('OnSigninRedirect');
          }
        }}
      >
        <LoaderOrApp />
      </AuthProvider>
    );
  };
};
