import { Grid, Typography } from '@material-ui/core';
import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { usePositionPageStyles } from './PositionPage.styles';
import { useData } from './PositionPage.hooks';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import IconButton from '../../../../../components/buttons/IconButton/IconButton';
import PositionContent from './PositionContent';
import { FslButton } from '../../../../../components/buttons/FslButton/FslButton';

interface IPositionPageProps {
  onNext: () => void;
  onPrevious: () => void;
}

const PositionPage: FunctionComponent<IPositionPageProps> = ({ onNext, onPrevious }) => {
  const { translateString } = useTranslation();
  const classes = usePositionPageStyles();

  const { positions, addPosition, removePosition, next, showErrors, errors } = useData(onNext);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={1}>
          {positions.map((p, index) => {
            const hasError = (err: boolean) => {
              errors.current[index] = err;
            };

            return (
              <Fragment key={p.key}>
                {index > 0 && <div className={classes.contentSeparator} />}

                <PositionContent position={p} index={index} showErrors={showErrors} hasError={hasError} />

                {positions.length > 1 && (
                  <div className={classes.addAndRemovePositionSection} style={{ marginBottom: '15px' }}>
                    <IconButton onClick={() => removePosition(index)}>
                      <div style={{ display: 'flex' }}>
                        <RemoveCircleIcon className={classes.removeCircleIcon} />
                        <div className={classes.addAndRemovePositionText}>
                          <Typography variant="body1">{translateString('remove_position')}</Typography>
                        </div>
                      </div>
                    </IconButton>
                  </div>
                )}
              </Fragment>
            );
          })}

          <Grid container spacing={1} style={{ marginBottom: '15px' }}>
            <Grid item xs={12} sm={12}>
              <div className={classes.addAndRemovePositionSection}>
                <IconButton onClick={addPosition}>
                  <div style={{ display: 'flex' }}>
                    <AddCircleIcon className={classes.addCircleIcon} />
                    <div className={classes.addAndRemovePositionText}>
                      <Typography variant="body1">{translateString('add_another_position')}</Typography>
                    </div>
                  </div>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <ButtonGroup stepperProgress={2} next={next({ bypassValidation: false })} previous={onPrevious} />
      <div className={classes.bypassButton}>
        <FslButton link onClick={next({ bypassValidation: true })}>
          {translateString('not_in_a_position')}
        </FslButton>
      </div>
    </>
  );
};

export default PositionPage;
