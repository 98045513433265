import { useCallback, useContext } from 'react';
import { useValidator, useTranslation } from '@fsl/fsl.common.npm';
import { NewMemberContext } from '../../../newMemberContext';
import { EditableInformationData } from './InformationPage.types';
import { validatorMessageTranslate } from '../../../../../tools/formatters';

export const useData = () => {
  const { state, dispatch } = useContext(NewMemberContext);
  const { translateString } = useTranslation();
  const validator = useValidator<Partial<EditableInformationData>>((v) => ({
    address: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'address' }), (s) => s.address),
    country: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'country' }), (s) => s.country),
    postalCode: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'postal_code' }), (s) => s.postalCode),
    city: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'city' }), (s) => s.city),
    ssn: [
      v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'ssn' }), (s) => s.ssn),
      v.validateSsn(validatorMessageTranslate(translateString, 'invalid_ssn'), (s) => s.ssn),
    ],
    firstName: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'firstnames' }), (s) => s.firstName),
    lastname: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'lastname' }), (s) => s.lastname),
    email: [
      v.validateEmail(validatorMessageTranslate(translateString, 'invalid_email'), (s) => s.email),
      v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'e-mail' }), (s) => s.email),
    ],
    phoneNumber: v.validateNotEmpty(validatorMessageTranslate(translateString, 'cannot_be_empty', { field: 'phone_number' }), (s) => s.phoneNumber),
  }));

  const updateData = useCallback(
    (data: Partial<EditableInformationData>) => {
      validator.showErrorsFor(data);
      dispatch({ type: 'setInformation', information: data });
    },
    [dispatch, validator]
  );

  validator.check(state.information ?? {}, {
    email: state.information?.email === state.information?.repeatedEmail ? undefined : translateString('similar_emails'),
  });

  return { data: state.information, updateData, validator };
};
