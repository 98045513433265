import { IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MuiModal from '@material-ui/core/Modal';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ModalOptions, ModalResult } from './Modal.types';
import { ModalContext } from '../../contexts/ModalProvider/ModalProvider';
import { useStyles } from './Modal.styles';

interface ICurrentModalState {
  title: string | null;
  ctor: React.ComponentType<any> | null;
  props: any;
  options: ModalOptions;
}

export default function Modal() {
  const { modalState: props, toggleOpen } = useContext(ModalContext);
  const [hasChanges, setHasChanges] = useState<boolean>();

  const InitialModalState = useCallback(
    () => (): ICurrentModalState => {
      return {
        title: props?.title ?? null,
        ctor: props?.componentConstructor ?? null,
        props: props?.props ?? null,
        options: props?.options ?? {},
      };
    },
    [props]
  );

  const [{ title, ctor: Ctor, options }, setCurrentModalState] = useState<ICurrentModalState>(InitialModalState());

  useEffect(() => {
    setCurrentModalState(InitialModalState());
  }, [props, InitialModalState]);

  const classes = useStyles();

  if (!Ctor) {
    return null;
  }

  let disableEscapeKeyDown;
  let disableBackdropClick;
  // If explicitly has changes then always disable esacpe and click-outside
  if (hasChanges === true) {
    disableEscapeKeyDown = true;
    disableBackdropClick = true;
  }
  // If explicitly has no changes then enable esacpe and click-outside unless options explicitly says otherwise
  else if (hasChanges === false) {
    disableEscapeKeyDown = options?.closeOnEscape !== undefined ? !options.closeOnEscape : false;
    disableBackdropClick = options?.closeOnClickOutside !== undefined ? !options.closeOnClickOutside : false;
  }
  // If hasChanges is undefined then disable esacpe and click-outside by default unless options says otherwise
  else if (hasChanges === undefined) {
    disableEscapeKeyDown = !options?.closeOnEscape;
    disableBackdropClick = !options?.closeOnClickOutside;
  }

  const clearInstance = () => {
    setCurrentModalState(InitialModalState());
    setHasChanges(undefined);
  };

  function doClose(result?: ModalResult, data?: any) {
    setTimeout(() => {
      options?.onClose?.(result, data);
      if (result === 'Success') options?.onSuccess?.(data);
      else if (result === 'Failure') options?.onFailure?.(data);

      toggleOpen();
    }, 100);
  }

  function onClose() {
    doClose();
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props?.open ?? false}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <Fade in={props?.open} timeout={500} onExited={clearInstance}>
        <div className={classes.paper} style={{ width: options?.width }}>
          <div className={classes.headerWrapper}>
            {title && (
              <h4 className={classes.heading} id="transition-modal-title">
                {title?.toUpperCase()}
              </h4>
            )}

            {!options.hideCloseButton && (
              <div className={classes.closeButtonWrapper}>
                <IconButton onClick={onClose}>
                  <ClearRoundedIcon className={classes.close} />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.modalContent}>
            <Ctor closeModal={doClose} hasChanges={hasChanges} setHasChanges={setHasChanges} {...(props?.props ?? {})} />
          </div>
        </div>
      </Fade>
    </MuiModal>
  );
}
