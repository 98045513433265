import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import FslTextField from '../../../../../components/user-input/input/FslTextField/FslTextField';
import { EditablePositionData } from './PositionPage.types';
import { FslCheckBox } from '../../../../../components/FslCheckbox/FslCheckbox';
import FSLDatePicker from '../../../../../components/user-input/picker/FSLDatePicker/FSLDatePicker';
import SimpleSelect from '../../../../../components/selectComponents/SimpleSelect/SimpleSelect';
import { usePositionPageStyles } from './PositionPage.styles';
import { useData } from './PositionContent.hooks';
import SchoolPicker from '../../../../../components/user-input/picker/SchoolPicker/SchoolPicker';
import FadeComponent from '../../../../../components/FadeComponent/FadeComponent';
import FreeTextSchoolPosition from './FreeTextSchoolPosition';

interface IPositionContentProps {
  position: Partial<EditablePositionData>;
  index: number;
  showErrors: boolean;
  hasError: (err: boolean) => void;
}

const PositionContent: FunctionComponent<IPositionContentProps> = ({ position, index, showErrors, hasError }) => {
  const classes = usePositionPageStyles();
  const { translateString } = useTranslation();

  const { updatePosition, validator, rateOptions, fetchSchools, onSchoolChanged, onPositionChanged, positionOptions, selectedPosition, selectedSchool } =
    useData(position, index);

  useEffect(() => {
    if (showErrors) {
      validator.setShowErrors(true);
    }
  }, [showErrors, validator]);

  useEffect(() => {
    hasError(validator.anyHasErrors);
  }, [hasError, validator.anyHasErrors]);

  return (
    <FadeComponent>
      <div style={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.schoolPickerGrid}>
            <SchoolPicker
              autoFocus
              title={translateString('school')}
              fetchOptions={fetchSchools}
              error={validator.shouldShowErrors('school')}
              helperText={validator.getErrorsForDisplay('school')}
              onChange={onSchoolChanged}
              value={selectedSchool}
              disabled={position?.workplaceNotInList}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.workplaceNotInListGrid}>
          <Grid item xs={12}>
            <FslCheckBox
              checkboxes={[
                [
                  { label: translateString('workplace_missing') },
                  {
                    checked: position.workplaceNotInList ?? false,
                    onChange: (e) => {
                      updatePosition({ workplaceNotInList: e.target.checked });
                      onSchoolChanged(undefined);
                    },
                  },
                ],
              ]}
            />
          </Grid>
          <FreeTextSchoolPosition
            position={position}
            validator={validator}
            updatePosition={updatePosition}
            showSchoolTextField={position?.workplaceNotInList ?? false}
            showPositionTextField={position?.jobTitleNotInList ?? false}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div className={classes.positionSelector}>
              {positionOptions && (
                <SimpleSelect
                  options={positionOptions}
                  inputLabel={translateString('position')}
                  onChange={(e) => onPositionChanged(e.target.value)}
                  value={selectedPosition?.id}
                  error={validator.shouldShowErrors('position')}
                  helperText={validator.getErrorsForDisplay('position')}
                />
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FSLDatePicker
              label={translateString('employment_start')}
              date={position.startDate}
              onDateChanged={(d) => updatePosition({ startDate: d })}
              error={validator.shouldShowErrors('startDate')}
              helperText={validator.getErrorsForDisplay('startDate')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.rateSelector}>
              <SimpleSelect
                inputLabel={translateString('employment_percent')}
                onChange={(e) => updatePosition({ employmentRate: e.target.value })}
                value={position.employmentRate}
                options={rateOptions}
                error={validator.shouldShowErrors('employmentRate')}
                helperText={validator.getErrorsForDisplay('employmentRate')}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.firstJobAsTeacherGrid}>
          <Grid item xs={12} sm={12}>
            <FslCheckBox
              checkboxes={[
                [
                  { label: translateString('first_job_as_teacher') },
                  { checked: position.firstJobAsTeacher ?? false, onChange: (e) => updatePosition({ firstJobAsTeacher: e.target.checked }) },
                ],
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <div className={classes.otherTextarea}>
              <FslTextField
                label={translateString('other_thing_about_employment')}
                multiline
                rowsMax={4}
                value={position.miscellaneousText}
                onChange={(t) => updatePosition({ miscellaneousText: t })}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </FadeComponent>
  );
};

export default PositionContent;
