import React, { FunctionComponent } from 'react';
import { FslButton, IFslButtonProps } from '../../../../../components/buttons/FslButton/FslButton';
import { useFlowButtonStyles } from './FlowButton.styles';

const FlowButton: FunctionComponent<IFslButtonProps> = (props) => {
  const classes = useFlowButtonStyles();
  return (
    <div className={classes.root}>
      <FslButton {...props}>{props.children}</FslButton>
    </div>
  );
};

export default FlowButton;
