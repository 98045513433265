import { useMemo } from 'react';
import { mapToMyDistrictData, mapToMyMembershipData, mapToMySchoolData } from './Dashboard.mapping';
import { TileInformationApiResponseData } from '../../../api/responseTypes';

export const useMapping = (data: TileInformationApiResponseData) => {
  const myMembershipMapping = useMemo(() => {
    if (data) {
      return mapToMyMembershipData(data);
    }

    return undefined;
  }, [data]);

  const mySchoolDataMapping = useMemo(() => {
    if (data) {
      return mapToMySchoolData(data);
    }

    return undefined;
  }, [data]);

  const myDistrictDataMapping = useMemo(() => {
    if (data) {
      return mapToMyDistrictData(data);
    }

    return undefined;
  }, [data]);

  return {
    myMembershipData: myMembershipMapping,
    mySchoolData: mySchoolDataMapping,
    myDistrictData: myDistrictDataMapping,
  };
};
