import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useDesktopNavbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '250px',
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      minWidth: '250px',
      minHeight: '100vh',
      borderRight: theme.customPalette.red,
      display: 'flex',
      flexDirection: 'column',
      borderRightColor: theme.customPalette.red,
      borderRightStyle: 'solid',
      borderRightWidth: 1,
    },
    menuList: {
      listStyleType: 'none',
      padding: '0px',
      color: theme.customPalette.white,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
    },
    separator: {
      borderBottom: `1px solid ${theme.customPalette.red}`,
      width: '200px',
      margin: `${theme.spacing()}px 0 ${theme.spacing()}px ${theme.spacing()}px`,
      [theme.breakpoints.down('xs')]: {
        width: '345px',
      },
    },
  })
);
