import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useButtonGroupStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        '& button': {
          minWidth: '150px',
        },
      },
    },
    buttonSeparator: {
      width: '40px',
    },
  })
);
