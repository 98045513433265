import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useSchoolTileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.white,
      color: theme.customPalette.darkBlue,
    },
    svg: {
      '& svg': {
        stroke: theme.customPalette.red,
        '& path': {
          stroke: theme.customPalette.red,
        },
        '& g': {
          stroke: theme.customPalette.red,
        },
      },
    },
    icon: {
      paddingTop: '5px',
    },
  })
);
