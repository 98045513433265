import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React, { PropsWithChildren, useState } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../config';
import { ApplicationInsightsContext } from './ApplicationInsightsContext';

function configureApplicationInsights(history: any): { reactPlugin: ReactPlugin; appInsights: ApplicationInsights } | null {
  const { applicationInsightsKey } = config;
  if (!applicationInsightsKey) {
    return null;
  }

  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: applicationInsightsKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
      enableCorsCorrelation: true,
      autoTrackPageVisitTime: true,
      enableUnhandledPromiseRejectionTracking: true,
      enableDebugExceptions: config.deployEnvironment === 'develop',
    },
  });

  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((item) => {
    if (!item) return;

    item.tags!['ai.cloud.role'] = 'FSL.MemberPortal.Frontend';
  });

  appInsights.addTelemetryInitializer((item) => {
    if (item.data?.message === 'Error: No matching state found in storage') {
      return false;
    }

    return true;
  });

  appInsights.context.application.ver = `${process.env.REACT_APP_VERSION}`;

  return { reactPlugin, appInsights };
}

export function ApplicationInsightsProvider({ children }: PropsWithChildren<{}>) {
  const history = useHistory();
  const [ai, setAi] = useState<{ reactPlugin: ReactPlugin; appInsights: ApplicationInsights } | null>();

  if (ai === undefined) {
    setAi(configureApplicationInsights(history));
    return <></>;
  }

  return <ApplicationInsightsContext.Provider value={ai}>{children}</ApplicationInsightsContext.Provider>;
}
