import { Button, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from '@fsl/fsl.common.npm';
import { useLanguageSelectorStyles } from './LanguageSelector.styles';

type Language = 'da' | 'en';

const LanguageSelector: FunctionComponent = () => {
  const classes = useLanguageSelectorStyles();
  const { changeLanguage } = useTranslation();
  const [language, setLanguage] = useState<Language>('da');

  const handleOnClick = useCallback(
    (l: Language) => () => {
      setLanguage(l);
      changeLanguage(l);
    },
    [setLanguage, changeLanguage]
  );

  return (
    <div className={classes.root}>
      <div className={language === 'da' ? clsx(classes.item, classes.activeItem) : classes.item}>
        <Button variant="text" onClick={handleOnClick('da')}>
          DA
        </Button>
      </div>

      <div className={clsx(classes.item, classes.separator)}>
        <Typography>|</Typography>
      </div>

      <div className={language === 'en' ? clsx(classes.item, classes.activeItem) : classes.item}>
        <Button variant="text" onClick={handleOnClick('en')}>
          ENG
        </Button>
      </div>
    </div>
  );
};

export default LanguageSelector;
