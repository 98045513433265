import { format } from 'date-fns';
import { Rate } from '../../../../../api/responseTypes';
import { EditableEmploymentData } from '../EmploymentPage/EmploymentPage.types';
import { EditableInformationData } from '../InformationPage/InformationPage.types';
import { EditablePositionData } from '../PositionPage/PositionPage.types';
import { NewMemberApi, NewMemberPositionApi } from './AcknowledgePage.types';

export const mapToApi = (
  acceptedTermsAndConditions: string,
  employment: Partial<EditableEmploymentData>,
  information: Partial<EditableInformationData>,
  positions: Partial<EditablePositionData>[],
  byPassPosition: boolean
): NewMemberApi => {
  return {
    acceptedTermsAndConditions,
    occupation: employment.employmentId ?? 0,
    occupationText: employment.employmentText ?? null,
    firstName: information.firstName ?? '',
    ssn: information.ssn ? information.ssn.replace('-', '') : '',
    lastname: information.lastname ?? '',
    address: information.address ?? '',
    postalCode: information.postalCode ?? '',
    city: information.city ?? '',
    municipality: information.municipality ?? '',
    country: information.country ?? '',
    co: information.co ?? null,
    email: information.email ?? '',
    phoneNumber: information.phoneNumber ?? '',
    employments: byPassPosition ? [] : mapEmployments(positions),
  };
};

const mapEmployments = (positions: Partial<EditablePositionData>[]): NewMemberPositionApi[] => {
  return positions.map((p) => {
    return {
      schoolId: p.school?.id,
      schoolNameNotInList: p.schoolNameNotInList ?? null,
      workplaceNotInList: p.workplaceNotInList ?? false,
      jobTitleId: p.position?.guid,
      jobTitleDescription: p.positionDescription ?? null,
      jobtitleNotInList: p.jobTitleNotInList ?? false,
      startDate: p.startDate ? format(p.startDate, 'yyyy-MM-dd') : '',
      employmentRate: findEmploymentRate(p.employmentRate),
      firstJobAsTeacher: p.firstJobAsTeacher ?? false,
      miscellaneousText: p.miscellaneousText ?? null,
    };
  });
};

const findEmploymentRate = (id: string | undefined): Rate | '' => {
  switch (id) {
    case '2': {
      return 'AboveFiftyPercent';
    }
    case '3': {
      return 'BelowFiftyPercent';
    }
    default: {
      return '';
    }
  }
};
