import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { Grid } from '@material-ui/core';
import FslTextField from '../../../../../components/user-input/input/FslTextField/FslTextField';
import { useInformationPageStyles } from './InformationPage.styles';
import AddressPicker from '../../../../../components/user-input/picker/AdressPicker/AddressPicker';
import { useData } from './InformationPage.hooks';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import { FslAddress } from '../../../../../components/user-input/picker/AdressPicker/AddressPicker.types';
import SelectWithSearch from '../../../../../components/user-input/input/SelectWithSearch/SelectWithSearch';

interface IInformationProps {
  onNext: () => void;
  onPrevious: () => void;
}

const InformationPage: FunctionComponent<IInformationProps> = ({ onNext, onPrevious }) => {
  const { translateString } = useTranslation();
  const classes = useInformationPageStyles();
  const { data, updateData, validator } = useData();

  const next = useCallback(() => {
    validator.setShowErrors(true);
    if (!validator.anyHasErrors) {
      onNext();
    }
  }, [onNext, validator]);

  const handleAddressPickerOnChange = useCallback(
    (newValue: Partial<FslAddress>) => {
      if (newValue) {
        updateData({
          postalCode: newValue.postalCode,
          city: newValue.city,
          municipality: newValue.municipality,
          country: newValue.countryCode,
          address: newValue.address,
        });
      }
    },
    [updateData]
  );

  const countryOptions = [
    { key: 'DK', name: 'Danmark' },
    { key: 'DE', name: 'Tyskland' },
    { key: 'SE', name: 'Sverige' },
    { key: 'NO', name: 'Norge' },
    { key: 'FI', name: 'Finland' },
  ] as { key: string; name: string }[];

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <SelectWithSearch<{ key: string; name: string }>
                value={countryOptions.find((x) => x.key === data?.country)}
                getOptionLabel={(x) => x.name}
                getOptionSelected={(l, r) => l.key === r.key}
                options={countryOptions}
                onChange={(_, v) => {
                  if (v) updateData({ country: v.key });
                }}
                title={translateString('country')}
                error={validator.shouldShowErrors('country')}
                helperText={validator.getErrorsForDisplay('country')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FslTextField
                autoFocus
                value={data?.ssn}
                onChange={(v) => updateData({ ssn: v })}
                label={translateString('ssn')}
                title={translateString('ssn')}
                error={validator.shouldShowErrors('ssn')}
                helperText={validator.getErrorsForDisplay('ssn')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.firstName}
                onChange={(v) => updateData({ firstName: v })}
                label={translateString('firstnames')}
                title={translateString('firstnames')}
                error={validator.shouldShowErrors('firstName')}
                helperText={validator.getErrorsForDisplay('firstName')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.lastname}
                onChange={(v) => updateData({ lastname: v })}
                label={translateString('lastname')}
                title={translateString('lastname')}
                error={validator.shouldShowErrors('lastname')}
                helperText={validator.getErrorsForDisplay('lastname')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AddressPicker title={`${translateString('find_address')}`} onChange={handleAddressPickerOnChange} disabled={data?.country !== 'DK'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.address}
                disabled={data?.country === 'DK'}
                isReadOnly={data?.country === 'DK'}
                label={translateString('address')}
                title={translateString('address')}
                error={validator.shouldShowErrors('address')}
                helperText={validator.getErrorsForDisplay('address')}
                onChange={(v) => updateData({ address: v })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FslTextField value={data?.co} onChange={(v) => updateData({ co: v })} label="C/o" title="C/o" />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FslTextField
                value={data?.postalCode}
                disabled={data?.country === 'DK'}
                isReadOnly={data?.country === 'DK'}
                label={translateString('postal_code')}
                title={translateString('postal_code')}
                error={validator.shouldShowErrors('postalCode')}
                helperText={validator.getErrorsForDisplay('postalCode')}
                onChange={(v) => updateData({ postalCode: v })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.city}
                disabled={data?.country === 'DK'}
                isReadOnly={data?.country === 'DK'}
                label={translateString('city')}
                title={translateString('city')}
                error={validator.shouldShowErrors('city')}
                helperText={validator.getErrorsForDisplay('city')}
                onChange={(v) => updateData({ city: v })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.municipality}
                disabled={data?.country === 'DK'}
                isReadOnly={data?.country === 'DK'}
                label={translateString('municipality')}
                title={translateString('municipality')}
                onChange={(v) => updateData({ municipality: v })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.email}
                onChange={(v) => updateData({ email: v })}
                label={translateString('e-mail')}
                title={translateString('e-mail')}
                error={validator.shouldShowErrors('email')}
                helperText={validator.getErrorsForDisplay('email')}
                updateDelay={100}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.repeatedEmail}
                onChange={(v) => updateData({ repeatedEmail: v })}
                label={translateString('repeat_email')}
                title={translateString('repeat_email')}
                updateDelay={100}
                onPaste={(e) => e.preventDefault()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FslTextField
                value={data?.phoneNumber}
                onChange={(v) => updateData({ phoneNumber: v })}
                label={translateString('phone_number')}
                title={translateString('phone_number')}
                error={validator.shouldShowErrors('phoneNumber')}
                helperText={validator.getErrorsForDisplay('phoneNumber')}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ButtonGroup stepperProgress={1} next={next} previous={onPrevious} />
    </>
  );
};

export default InformationPage;
