import React, { FunctionComponent } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Routing from '../../../../../routing/routing';
import { useMobileDeviceNavbarStyles } from '../../MobileDeviceNavbar.styles';
import { useSchoolTileStyles } from './SchoolTile.styles';
import { ReactComponent as ArrowIcon } from '../../../../../images/Pil.svg';
import { MySchoolData } from '../../Dashboard.types';

interface ISchoolTileProps {
  data?: MySchoolData;
}

const SchoolTile: FunctionComponent<ISchoolTileProps> = ({ data }) => {
  const navbarClasses = useMobileDeviceNavbarStyles();
  const tileClasses = useSchoolTileStyles();
  const { translateString } = useTranslation();

  return (
    <NavLink to={Routing.school} className={`${navbarClasses.tile} ${tileClasses.root}`} exact>
      <Grid container spacing={1} className={navbarClasses.tilePadding}>
        <Grid item title={translateString('my_school')} sm={12}>
          <div className={navbarClasses.tileText}>{translateString('my_school')}</div>
        </Grid>
        <Grid item sm={12}>
          {data?.schoolName}
        </Grid>
        <Grid item sm={12}>
          {data?.schoolEmail}
        </Grid>
        <div className={navbarClasses.playIcon}>
          <ArrowIcon />
        </div>
      </Grid>
    </NavLink>
  );
};

export default SchoolTile;
