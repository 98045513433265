import React, { createContext, FunctionComponent, useCallback, useReducer } from 'react';
import { UserSelectionState, UserSelectionType } from './UserSelectionContext.types';
import reducer from './UserSelectionContext.reducer';

const initialState: UserSelectionState = {
  selectedSchoolId: undefined,
  selectedDistrictId: undefined,
  selectedInboxPage: 1,
  selectedSentMessagesPage: 1,
};

export const UserSelectionContext = createContext<UserSelectionType>({
  state: initialState,
  setSelectedSchoolId: () => null,
  setSelectedDistrictId: () => null,
  setSelectedInboxPage: () => null,
  setSelectedSentMessagesPage: () => null,
});

export const UserSelectionProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSelectedSchoolId = useCallback((schooldId: string | undefined) => {
    dispatch({ type: 'setSelectedSchoolId', schooldId });
  }, []);

  const setSelectedDistrictId = useCallback((districtId: string | undefined) => {
    dispatch({ type: 'setSelectedDistrictId', districtId });
  }, []);

  const setSelectedInboxPage = useCallback((inboxPage: number) => {
    dispatch({ type: 'setSelectedInboxPage', inboxPage });
  }, []);

  const setSelectedSentMessagesPage = useCallback((sentMessagesPage: number) => {
    dispatch({ type: 'setSelectedSentMessagesPage', sentMessagesPage });
  }, []);

  return (
    <UserSelectionContext.Provider value={{ state, setSelectedSchoolId, setSelectedDistrictId, setSelectedInboxPage, setSelectedSentMessagesPage }}>
      {children}
    </UserSelectionContext.Provider>
  );
};
