import { Step, StepLabel, Stepper } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Panel from '../../../layout/Panel/Panel';
import { useNewMemberFlowStyles } from './NewMemberFlow.styles';
import { useScreenSizeProvider } from '../../../hooks/useScreenSizeProvider';
import FadeComponent from '../../../components/FadeComponent/FadeComponent';
import SlideComponent from '../../../components/SlideComponent/SlideComponent';
import { useData } from './NewMemberFlow.hooks';

const NewMemberFlow: FunctionComponent<{ step?: number }> = ({ step }) => {
  const { screenSize } = useScreenSizeProvider();

  const smallScreen = screenSize === 'SmallScreen';

  const { content, selectedContent, stepperProgress, toggleAnimation, slideDirection } = useData(smallScreen, step);

  const classes = useNewMemberFlowStyles();

  return (
    <Panel>
      <div style={{ width: '100%' }}>
        {stepperProgress !== 4 && (
          <Stepper alternativeLabel={!smallScreen} orientation={smallScreen ? 'vertical' : 'horizontal'}>
            {content
              .filter((c) => c.showInStepper)
              .map((c) => (
                <Step key={c.orderIndex}>
                  <StepLabel StepIconComponent={() => <StepIcon isCompleted={stepperProgress >= c.orderIndex} />}>
                    <span className={stepperProgress === c.orderIndex ? classes.activeStepperText : ''}>{c.title}</span>
                  </StepLabel>
                  {smallScreen && c.orderIndex === stepperProgress ? (
                    <FadeComponent>
                      <div className={classes.mobileComponentWrapper}>{c.component}</div>
                    </FadeComponent>
                  ) : null}
                </Step>
              ))}
          </Stepper>
        )}
        {smallScreen && stepperProgress === 4 && (
          <FadeComponent>
            <div>
              <h2 className={classes.contentHeader}>{selectedContent.title}</h2>
              <div className={classes.content}>
                <div className={classes.centerContent}>{selectedContent.component}</div>
              </div>
            </div>
          </FadeComponent>
        )}
        {!smallScreen && toggleAnimation && (
          <SlideComponent slideDirection={slideDirection}>
            <div>
              <h2 className={classes.contentHeader}>{selectedContent.title}</h2>
              <div className={classes.content}>
                <div className={classes.centerContent}>{selectedContent.component}</div>
              </div>
            </div>
          </SlideComponent>
        )}
      </div>
    </Panel>
  );
};

const StepIcon: FunctionComponent<{ isCompleted: boolean }> = ({ isCompleted }) => {
  const classes = useNewMemberFlowStyles();
  return <div className={`${classes.stepperIcon} ${isCompleted ? classes.stepperIconComplete : ''}`} />;
};

export default NewMemberFlow;
