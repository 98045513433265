import { useTranslation } from '@fsl/fsl.common.npm';
import { useCallback } from 'react';
import { MemberType } from '../api/responseTypes';

export const useMemberTypeFormatter = () => {
  const { translateString } = useTranslation();

  const formatMemberType = useCallback(
    (memberType: MemberType | undefined) => {
      if (!memberType) {
        return '';
      }
      let memberTypeText = '';

      switch (memberType) {
        case 'Employed':
          memberTypeText = translateString('employed');
          break;
        case 'Left': {
          memberTypeText = translateString('membertype_left');
          break;
        }
        case 'Retired':
          memberTypeText = translateString('membertype_retired');
          break;
        case 'Student':
          memberTypeText = translateString('membertype_student');
          break;
        case 'Unemployed':
          memberTypeText = translateString('membertype_unemployed');
          break;
        default:
          memberTypeText = translateString('membertype_other');
      }

      return memberTypeText;
    },
    [translateString]
  );

  return { formatMemberType };
};
