import { useCallback } from 'react';
import { get } from '../api';
import { TileInformationApiResponseData } from '../api/responseTypes';

export const useTileInformationEndpoint = () => {
  const endpoint = '/tile/me';

  const fetchTileInformation = useCallback(async (): Promise<TileInformationApiResponseData> => {
    const response = await get(endpoint);
    return response.json();
  }, []);

  return { fetchTileInformation, endpoint };
};
