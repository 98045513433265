import React, { FunctionComponent } from 'react';
import { AutocompleteCloseReason } from '@material-ui/lab';
import SelectWithSearch from '../../input/SelectWithSearch/SelectWithSearch';
import { DawaAutocompleteResult, FslAddress } from './AddressPicker.types';
import { useData } from './AddressPicker.hooks';

interface IAddressPickerProps {
  onChange?: (changes: Partial<FslAddress>) => void;
  error?: boolean;
  helperText?: string;
  title?: string;
  disabled?: boolean;
}

const AddressPicker: FunctionComponent<IAddressPickerProps> = ({ onChange, error, helperText, title, disabled }) => {
  const { query, options, isOpen, setIsOpen, handleOnChange, handleOnInputChanged, selectedOption } = useData(onChange);

  return (
    <SelectWithSearch<DawaAutocompleteResult>
      value={selectedOption}
      title={title}
      error={error}
      helperText={helperText}
      onChange={handleOnChange}
      options={options}
      inputValue={query.q}
      onInputChange={handleOnInputChanged}
      updateDelay={50}
      open={isOpen}
      isReadOnly={disabled}
      isDisabled={disabled}
      onOpen={() => setIsOpen(true)}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.forslagstekst}
      getOptionSelected={(l, r) => l.forslagstekst === r.forslagstekst}
      onClose={(_, reason: AutocompleteCloseReason) => {
        if (reason === 'toggleInput') {
          setIsOpen(false);
        }
      }}
    />
  );
};

export default AddressPicker;
