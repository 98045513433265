import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { SlideDirection } from '../../../components/SlideComponent/SlideComponent';
import EmploymentPage from './flowPages/EmploymentPage/EmploymentPage';
import InformationPage from './flowPages/InformationPage/InformationPage';
import PositionPage from './flowPages/PositionPage/PositionPage';
import AcknowledgePage from './flowPages/AcknowledgePage/AcknowledgePage';
import FinalPage from './flowPages/FinalPage/FinalPage';

export const useData = (smallScreen: boolean, step: number | undefined) => {
  const { translateString } = useTranslation();
  const [toggleAnimation, setToggleAnimation] = useState(true);
  const [slideDirection, setSlideDirection] = useState<SlideDirection>(undefined);
  const [stepperProgress, setStepperProgress] = useState<number>(step ?? 0);
  const redirectToFslPage = true;

  useEffect(() => {
    if (!toggleAnimation) {
      setToggleAnimation(true);
    }
  }, [toggleAnimation]);

  const previous = useCallback(() => {
    setToggleAnimation(false);
    setSlideDirection(smallScreen ? 'down' : 'right');
    setStepperProgress((prev) => prev - 1);
  }, [smallScreen]);

  const next = useCallback(() => {
    setToggleAnimation(false);
    setSlideDirection(smallScreen ? 'up' : 'left');
    setStepperProgress((prev) => prev + 1);
  }, [smallScreen]);

  const acknowledgePageNext = useCallback(() => {
    if (redirectToFslPage) {
      window.location.replace('https://www.fsl.dk/tak-for-din-indmeldelse-husk-at-bekraefte-din-mailadresse/');
    } else {
      next();
    }
  }, [next, redirectToFslPage]);

  const content = useMemo(
    () => [
      {
        title: translateString('employment'),
        component: <EmploymentPage onNext={next} onPrevious={previous} />,
        orderIndex: 0,
        showInStepper: true,
      },
      {
        title: translateString('your_information'),
        component: <InformationPage onNext={next} onPrevious={previous} />,
        orderIndex: 1,
        showInStepper: true,
      },
      {
        title: translateString('terms_of_employment'),
        component: <PositionPage onNext={next} onPrevious={previous} />,
        orderIndex: 2,
        showInStepper: true,
      },
      {
        title: translateString('acknowledge_and_send'),
        component: <AcknowledgePage onNext={acknowledgePageNext} onPrevious={previous} />,
        orderIndex: 3,
        showInStepper: true,
      },
      ...(!redirectToFslPage
        ? [
            {
              title: translateString('request_thanks'),
              component: <FinalPage />,
              orderIndex: 4,
              showInStepper: false,
            },
          ]
        : []),
    ],
    [translateString, next, previous, acknowledgePageNext, redirectToFslPage]
  );

  const selectedContent = content[stepperProgress];

  return { content, selectedContent, stepperProgress, toggleAnimation, slideDirection };
};
