import { useCallback, useEffect, useState } from 'react';
import { getDawaAutocompleteAddresses, getDawaMunicipality } from '../../../../api';
import { DawaAdresse, DawaAutocompleteResult, DawaKommuneOpslagResult, DawaQueryOptions, DawaType, FslAddress } from './AddressPicker.types';

export const useData = (onChange?: (changes: Partial<FslAddress>) => void) => {
  const [query, setQuery] = useState<DawaQueryOptions>({ fuzzy: '', q: '' });
  const [options, setOptions] = useState<DawaAutocompleteResult[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<DawaAdresse | undefined>();
  const [selectedOption, setSelectedOption] = useState<DawaAutocompleteResult | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const doFetch = async () => {
      const newOptions: DawaAutocompleteResult[] = await getDawaAutocompleteAddresses(query);
      setOptions(newOptions);
    };

    doFetch();
  }, [query]);

  useEffect(() => {
    const doFetch = async () => {
      if (selectedAddress && selectedAddress.kommunekode) {
        const address = `${selectedAddress.vejnavn} ${selectedAddress.husnr}${selectedAddress.etage ? `, ${selectedAddress.etage}` : ''}${
          selectedAddress.dør ? `, ${selectedAddress.dør}` : ''
        }`;
        const kommune: DawaKommuneOpslagResult = await getDawaMunicipality(selectedAddress.kommunekode);
        if (kommune) {
          onChange?.({
            address,
            postalCode: selectedAddress.postnr,
            city: selectedAddress.postnrnavn,
            countryCode: 'DK',
            municipality: kommune.navn,
          });
        }
      }
    };
    doFetch();
  }, [selectedAddress, onChange]);

  const handleOnChange = useCallback(
    (_: React.ChangeEvent<{}>, value: DawaAutocompleteResult | null) => {
      if (value && value.type !== DawaType.vejnavn) {
        setIsOpen(false);
        setSelectedAddress(value?.data as DawaAdresse);
      }
    },
    [setIsOpen, setSelectedAddress]
  );

  const handleOnInputChanged = useCallback(
    (_: React.ChangeEvent<{}>, newValue: string) => {
      setQuery({ ...query, q: newValue });
      setSelectedOption({ forslagstekst: newValue, type: DawaType.vejnavn, tekst: '', caretPos: 0, data: { id: '' } });
    },
    [setQuery, query]
  );

  return { query, options, isOpen, setIsOpen, handleOnChange, handleOnInputChanged, selectedOption };
};
