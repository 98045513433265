import 'whatwg-fetch';
import CONFIG from '../config';
import { userManager } from '../auth';
import { ApiOptions } from './types';

export type PathInit = string | { path: string; query?: any } | [string, any];

export async function fetchRequest(
  pathInit: PathInit,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'HEAD' | 'OPTION' = 'GET',
  body?: any,
  useAuthServer?: boolean,
  options?: ApiOptions
): Promise<Response> {
  const requestInit = await createRequestInit(body, method, options).catch(() => undefined);

  const baseUrl = useAuthServer ? CONFIG.authServer : CONFIG.apiUrl;
  const url = pathInitToString(pathInit);

  const response = await fetch(`${baseUrl}${url}`, requestInit);

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    await userManager.removeUser();
    return fetchRequest(pathInit, method, body, useAuthServer);
  }

  const error = new Error(response.statusText);
  (error as any).response = response;
  throw error;
}

async function createRequestInit(body: any, method: string, options?: ApiOptions): Promise<RequestInit> {
  let processedBody: any;
  if (body) {
    if (options?.uploadFile) {
      processedBody = body;
    } else {
      processedBody = JSON.stringify(body);
    }
  }
  const requestInit: RequestInit = {
    body: processedBody,
    method,
    headers: options?.uploadFile
      ? undefined
      : {
          'Content-Type': 'application/json',
        },
    credentials: 'same-origin',
  };

  if (options?.ignoreAuthentication) {
    return requestInit;
  }

  let user = await userManager.getUser();

  if (!user || user.expired) {
    try {
      await userManager.clearStaleState();
      await userManager.signinSilent();
    } catch {
      await userManager.signinRedirect();
    }

    user = await userManager.getUser();
  }

  if (!user) {
    await userManager.signinRedirect();
    throw new Error('Signing in');
  }

  requestInit.headers = { ...requestInit.headers, Authorization: `Bearer ${user.access_token}` };

  return requestInit;
}

export function parsePathInit(pathInit: PathInit) {
  let path: string;
  let query: object | undefined;
  if (typeof pathInit === 'string') {
    path = pathInit;
  } else if (Array.isArray(pathInit)) {
    [path, query] = pathInit;
  } else {
    path = pathInit.path;
    query = pathInit.query;
  }

  return { path, query };
}

export function pathInitToString(pathInit: PathInit) {
  const { path, query } = parsePathInit(pathInit);

  let queryString = Object.entries(query ?? {})
    .map(([key, value]) => (value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : ''))
    .join('&');
  if (queryString) queryString = `?${queryString}`;

  const url = `${path}${queryString}`;
  return url;
}
