import React from 'react';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel/FormControlLabel';
import { ReactComponent as CheckedIcon } from '../../images/Checked.svg';
import { ReactComponent as UncheckedIcon } from '../../images/Unchecked.svg';
import { useFslCheckboxStyles } from './FslCheckbox.styles';

export type FslCheckBoxTuple = [Omit<FormControlLabelProps, 'control'>, CheckboxProps];
export enum CheckboxVariant {
  Default,
  Red,
}
interface IFslCheckBoxProps {
  checkboxes: Array<FslCheckBoxTuple>;
  label?: string;
  helperText?: string;
  variant?: CheckboxVariant;
}

export function FslCheckBox({ label, checkboxes, helperText, variant = CheckboxVariant.Default }: IFslCheckBoxProps) {
  const classes = useFslCheckboxStyles();

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <FormGroup>
        {checkboxes.map(([formProps, checkboxProps]) => (
          <FormControlLabel
            className={classes.formControlLabel}
            key={formProps.label as any}
            control={
              <Checkbox
                checkedIcon={<CheckedIcon className={variant === CheckboxVariant.Red ? classes.checkedRedVariant : undefined} />}
                icon={<UncheckedIcon className={variant === CheckboxVariant.Red ? classes.uncheckedRedVariant : undefined} />}
                {...checkboxProps}
              />
            }
            {...formProps}
          />
        ))}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
