import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useLanguageSelectorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '50px',
      '& .MuiButton-text': {
        textDecoration: 'none',
        color: theme.customPalette.darkBlue,
        fontWeight: 'normal',
      },
    },
    item: {
      alignSelf: 'flex-end',
    },
    separator: {
      marginRight: '10px',
      marginLeft: '10px',
    },
    activeItem: {
      '& .MuiButton-text': {
        fontWeight: theme.fontWeight,
      },
    },
  })
);
