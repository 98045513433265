declare global {
  interface Window {
    runtimeConfig: {
      deployEnvironment: string;
      showComponentShowcases: boolean;
      applicationInsightsKey: string;

      apiUrl: string;
      authServer: string;
      postLogoutUrl: string;
    };
  }
}

const { runtimeConfig } = window;

export default {
  ...runtimeConfig,
  smBreakpoint: 767,
  datePattern: 'DD.MM.YYYY',
};
