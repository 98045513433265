import { CircularProgress } from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useContentStyles } from './ContentWrapper.styles';

interface IContentWrapperProps {
  title?: { text: string; showOnMobile?: boolean };
  subTitle?: string;
  isLoading?: boolean;
  rightCornerComponent?: ReactNode;
}

const ContentWrapper: FunctionComponent<IContentWrapperProps> = ({ title, subTitle, isLoading, children, rightCornerComponent }) => {
  const { windowHeight } = useWindowDimensions();
  const classes = useContentStyles({ windowHeight, showTitleOnMobile: title?.showOnMobile });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleSection}>
          <h1>{title?.text}</h1>
          <h2>{subTitle}</h2>
        </div>
        {rightCornerComponent && <div className={classes.rightCornerComponent}>{rightCornerComponent}</div>}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default ContentWrapper;
