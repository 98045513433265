import _ from 'lodash';
import { INewMemberContextState, NewMemberContextAction } from './newMemberContext.types';

export default function NewMemberContextReducer(state: INewMemberContextState, action: NewMemberContextAction): INewMemberContextState {
  switch (action.type) {
    case 'setEmployment': {
      return { ...state, employment: { ...state.employment, ...action.employment } };
    }
    case 'setInformation': {
      return { ...state, information: { ...state.information, ...action.information } };
    }
    case 'updatePositions': {
      const positions = [...state.positions];
      if (positions[action.index]) {
        positions[action.index] = { ...positions[action.index], ...action.position };
      } else {
        positions[action.index] = { ...positions[action.index], ...action.position, key: _.uniqueId() };
      }

      return { ...state, positions };
    }
    case 'setJobTitles': {
      return { ...state, jobTitles: action.jobTitles };
    }
    case 'removePosition': {
      const positions = [...state.positions];
      positions.splice(action.index, 1);
      return { ...state, positions };
    }
    case 'setEulaName': {
      return { ...state, acceptedTermsAndConditions: action.eulaName };
    }
    case 'setBypassPosition': {
      return { ...state, bypassPositions: action.value };
    }
    default: {
      return state;
    }
  }
}
