import { useCallback } from 'react';
import { post, put, get, deleteRequest } from '../api';
import {
  AttachmentApi,
  DraftApiResponse,
  MessagesApiResponse,
  PaginatedApiResponse,
  SenderApiResponse,
  SentMessagesApiResponse,
  ThreadApiResponse,
  ThreadMessageApiResponse,
} from '../api/responseTypes';
import { Recipient } from '../contexts/MessageContext/MessageContext.types';

export const useMessagesEndpoint = () => {
  const messagesEndpoint = '/messages';
  const draftEndpoint = `${messagesEndpoint}/drafts`;
  const threadEndpoint = `${messagesEndpoint}/threads`;

  const deleteMessage = useCallback(async (messageId: string) => {
    try {
      await deleteRequest(`${messagesEndpoint}/${messageId}`, undefined);
    } catch (error) {
      return false;
    }

    return true;
  }, []);

  const deleteThread = useCallback(
    async (threadId: string) => {
      try {
        await deleteRequest(`${threadEndpoint}/${threadId}`, undefined);
      } catch (error) {
        return false;
      }
      return true;
    },
    [threadEndpoint]
  );

  const fetchMessage = useCallback(async (messageId: string): Promise<ThreadMessageApiResponse> => {
    const result = await get(`${messagesEndpoint}/${messageId}`);
    if (result) {
      return result.json();
    }
    return new Promise(() => undefined);
  }, []);

  const fetchSentMessages = useCallback(async (pageSize: number, pageNumber: number): Promise<PaginatedApiResponse<SentMessagesApiResponse>> => {
    const result = await get(`${messagesEndpoint}/sent?pageSize=${pageSize}&pageNo=${pageNumber}`);
    if (result) {
      return result.json();
    }
    return new Promise(() => undefined);
  }, []);

  const deleteAttachment = useCallback(
    async (draftId: string, attachmentId: string) => {
      await deleteRequest(`${draftEndpoint}/${draftId}/attachments/${attachmentId}`, {});
    },
    [draftEndpoint]
  );

  const postAttachment = useCallback(
    async (file: File, draftId: string): Promise<AttachmentApi> => {
      const formData = new FormData();
      formData.append(file.name, file);
      const result = await post(formData, `${draftEndpoint}/${draftId}/attachments`, {
        uploadFile: true,
      });
      if (result) {
        return result.json();
      }
      return new Promise(() => undefined);
    },
    [draftEndpoint]
  );

  const fetchSenders = useCallback(async (): Promise<SenderApiResponse[]> => {
    const result = await get(`${messagesEndpoint}/contacts/senders`);
    if (result) {
      return result.json();
    }

    return new Promise(() => undefined);
  }, []);

  const sendMessage = useCallback(
    async (draftId: string) => {
      await post(undefined, `${draftEndpoint}/${draftId}/send`);
    },
    [draftEndpoint]
  );

  const fetchDraft = useCallback(
    async (draftId: string): Promise<DraftApiResponse | undefined> => {
      const result = await get(`${draftEndpoint}/${draftId}`);
      if (result) {
        return result.json();
      }
      return undefined;
    },
    [draftEndpoint]
  );

  const updateDraftReplyMessage = useCallback(
    (draftMessageId: string, body: string) => {
      put({ body }, `${draftEndpoint}/${draftMessageId}`);
    },
    [draftEndpoint]
  );

  const updateDraftNewMessage = useCallback(
    (draftMessageId: string, subject?: string, body?: string, recipientsCanSeeEachOther?: boolean) => {
      put({ subject, body, recipientsCanSeeEachOther }, `${draftEndpoint}/${draftMessageId}`);
    },
    [draftEndpoint]
  );

  const postReply = useCallback(
    async (parentId: string) => {
      const response = await post({ parentId }, draftEndpoint);
      const { id } = await response.json();
      return id;
    },
    [draftEndpoint]
  );

  const postDraftMessage = useCallback(
    async (subject?: string, body?: string, recipientsCanSeeEachOther?: boolean, recipients?: Recipient[], sender?: Recipient) => {
      const response = await post({ subject, body: body ?? '', recipientsCanSeeEachOther, recipients: recipients ?? [], sender }, draftEndpoint);
      const { id } = await response.json();
      return id;
    },
    [draftEndpoint]
  );

  const fetchMessages = useCallback(
    async (pageSize: number, pageNumber: number): Promise<PaginatedApiResponse<MessagesApiResponse>> => {
      const result = await get(`${threadEndpoint}?pageSize=${pageSize}&pageNo=${pageNumber}`);
      return result.json();
    },
    [threadEndpoint]
  );

  const fetchThread = useCallback(
    async (threadId: string): Promise<ThreadApiResponse> => {
      const result = await get(`${threadEndpoint}/${threadId}`);
      if (result) {
        return result.json();
      }

      return new Promise(() => []);
    },
    [threadEndpoint]
  );

  const markMessageAsRead = useCallback(async (obj: { id: string }): Promise<any> => {
    const response = await post(undefined, `/messages/${obj.id}/read`);
    return response;
  }, []);

  const fetchNumberOfReadMessages = useCallback(async () => {
    const response = await get('/messages/numberOfUnreadMessages');
    if (response) {
      return response.json();
    }

    return new Promise(() => 0);
  }, []);

  return {
    fetchMessage,
    fetchMessages,
    markMessageAsRead,
    deleteMessage,
    postDraftMessage,
    updateDraftNewMessage,
    updateDraftReplyMessage,
    sendMessage,
    fetchThread,
    fetchDraft,
    fetchNumberOfReadMessages,
    fetchSenders,
    postReply,
    postAttachment,
    deleteAttachment,
    fetchSentMessages,
    deleteThread,
  };
};
