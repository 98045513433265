import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { useTranslation } from '@fsl/fsl.common.npm';
import { useParams } from 'react-router-dom';
import { QueryStatus } from 'react-query';
import { ProgressSpinner } from '../../../../../components/feedback/ProgressSpinner/ProgressSpinner';
import { useMailValidationStyles } from './MailValidationPage.styles';
import { useFSLQuery } from '../../../../../hooks/useFslQueryHook';
import { postMailHasBeenValidated } from './MailValidation.actions';

export const useData = () => {
  const classes = useMailValidationStyles();
  const { translateString } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { error, isLoading, status } = useFSLQuery('validateEmail', () => postMailHasBeenValidated(id));

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className={classes.spinner}>
          <ProgressSpinner />
          <Typography variant="body1">{translateString('validating')}</Typography>
        </div>
      );
    }

    if (error) {
      return (
        <>
          <Typography variant="body1">{translateString('general_primary_error_message')}</Typography>
          <Typography variant="body1">{translateString('general_secondary_error_message')}</Typography>
        </>
      );
    }

    if (status === QueryStatus.Success) {
      return <Typography variant="body1">{translateString('email_verification_text')}</Typography>;
    }

    return undefined;
  }, [isLoading, error, status, classes.spinner, translateString]);

  return { content };
};
