import IMask from 'imask';
import { debounce } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { IMaskMixin } from 'react-imask';
import BasicTextField from '../BasicTextField/BasicTextField';

type IFslTextFieldProps = Omit<React.ComponentProps<typeof BasicTextField>, 'onChange' | 'defaultValue'> & {
  value?: unknown;
  mask?: IMask.AnyMaskedOptions | string;
  onChange?: (value: string) => void;
  updateDelay?: number;
};

const MaskedInput = IMaskMixin(({ inputRef, ref, ...props }: any) => {
  return <BasicTextField inputRef={inputRef} {...props} />;
});

function FslTextField({ value, mask, onChange, updateDelay, ...props }: IFslTextFieldProps, ref: React.Ref<HTMLDivElement>) {
  const [lazy, setLazy] = useState(true);

  const debounced = useMemo(() => ((updateDelay ?? 0) > 0 ? debounce((fn: () => void) => fn(), 500) : (f: () => void) => f()), [updateDelay]);
  const [actualValue, setActualValue] = useState<unknown>();

  useEffect(() => {
    setActualValue(value);
  }, [value]);

  function localOnChange(value: string) {
    setActualValue(value);

    debounced(() => onChange?.(value));
  }

  useEffect(() => {
    if (actualValue !== null && actualValue !== undefined) {
      setLazy(false);
    }
  }, [actualValue]);

  const strValue = `${actualValue ?? ''}`;

  if (!mask) {
    return (
      <BasicTextField
        value={strValue}
        variant={props.multiline ? 'outlined' : undefined}
        onChange={(e) => {
          const strNewValue = `${e.target.value ?? ''}`;
          if (strNewValue !== strValue) {
            localOnChange(strNewValue);
          }
        }}
        {...props}
      />
    );
  }

  return (
    <MaskedInput
      InputLabelProps={{ shrink: !lazy }}
      value={strValue}
      lazy={lazy}
      unmask
      onFocus={() => setLazy(false)}
      onAccept={(newValue: string) => {
        const strNewValue = `${newValue ?? ''}`;
        if (strNewValue !== strValue) {
          localOnChange(strNewValue);
        }
      }}
      {...(typeof mask === 'string' ? { mask } : mask)}
      ref={ref}
      {...props}
    />
  );
}

export default React.forwardRef(FslTextField);
