import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    customPalette: {
      darkBlue: string;
      red: string;
      white: string;
      black: string;
      lightBlue: string;
      blue: string;
      green: string;
      grey: string;
      yellow: string;
      lightGrey: string;
    };
    fontWeight: number;
  }
  interface ThemeOptions {
    customPalette: {
      darkBlue: string;
      red: string;
      white: string;
      black: string;
      lightBlue: string;
      blue: string;
      green: string;
      grey: string;
      yellow: string;
      lightGrey: string;
    };
    fontWeight: number;
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#006CCE',
      contrastText: '#fff',
    },
    secondary: {
      main: '#112F4A',
      contrastText: '#fff',
    },
  },
  customPalette: {
    darkBlue: '#112F4A',
    red: '#E21B37',
    white: '#FFF',
    black: '#000',
    lightBlue: '#006CCE',
    blue: '#006CCE',
    green: '#44AE38',
    grey: 'grey',
    yellow: '#ECD11F',
    lightGrey: '#F3F3F3',
  },
  spacing: 15,
  fontWeight: 600,
  overrides: {
    MuiTab: {
      root: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
    MuiInputLabel: {
      root: {
        fontStyle: 'italic',
        fontSize: '10px',
      },
      outlined: {
        fontSize: '14px',
      },
      shrink: {
        fontSize: '14px',
        fontStyle: 'normal',
      },
    },
    MuiStepLabel: {
      label: {
        color: 'black',
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '0',
        height: '64px',
      },
    },
    MuiPaper: {
      root: {
        color: 'none',
      },
      rounded: { borderRadius: 0 },
      elevation1: { boxShadow: '0', height: '100%' },
    },
    MuiButton: {
      root: {
        borderRadius: '17.5px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bolder',
        fontSize: '12px',
      },
      outlined: {
        boxShadow: 'none',
        fontWeight: 600,
        border: '2px solid !important',
        lineHeight: 1.5,
        fontFamily: 'Montserrat, sans-serif',
        padding: '5px 15px',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
        },
      },
      outlinedPrimary: {
        borderColor: `#006CCE !important`,
        color: '#006CCE',
      },
      outlinedSecondary: {
        borderColor: `${'#112F4A'} !important`,
      },
      contained: {
        boxShadow: 'none !important',
        '&:hover': {
          backgroundColor: '#d5d5d5 !important',
        },
      },
      containedPrimary: {
        backgroundColor: '#E21B37',
        color: '#FFF',
      },
      containedSecondary: {
        backgroundColor: '#006CCE',
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
      },
      text: {
        textDecoration: 'underline',
        margin: 0,
        padding: 0,
        display: 'inline',
        border: 0,
        width: 'unset',
        minWidth: 'unset',
        lineHeight: 'unset',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Montserrat, sans-serif',
        '&$error': {
          color: '#E21B37',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
    MuiNativeSelect: {
      root: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
      },
      body2: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '12px',
      },
      h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
      },
      h6: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat, sans-serif',
        paddingTop: '0',
        paddingBottom: '0',
      },
      body: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  },
});

export default theme;
