import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import CloseIcon from '@material-ui/icons/Close';
import Routing from '../../../../routing/routing';
import { useLogoStyles } from './logo.styles';
import { ReactComponent as LogoIcon } from '../../../../images/Aeble_ikon.svg';
import IconButton from '../../../../components/buttons/IconButton/IconButton';
import { usePathname } from './logo.hooks';
import useUser from '../../../../auth/UserService';

interface ILogoProps {
  subMenuOpened?: boolean;
  onBurgerClicked?: () => void;
  renderedOnDesktop: boolean;
}

const Logo: FunctionComponent<ILogoProps> = ({ onBurgerClicked, subMenuOpened, renderedOnDesktop }) => {
  const classes = useLogoStyles({ showBottomLine: !renderedOnDesktop && !subMenuOpened });
  const { logoSubtitle } = usePathname();

  const useUserName = useUser()?.name;

  return (
    <div className={classes.root}>
      <NavLink to={Routing.frontpage}>
        <div>
          <LogoIcon />
        </div>
      </NavLink>
      <div className={classes.logoText}>
        <span className={classes.header}>{logoSubtitle}</span>
        {useUserName}
        <span />
      </div>
      <div className={classes.menu}>
        <IconButton onClick={onBurgerClicked}>{subMenuOpened ? <CloseIcon /> : <MenuRoundedIcon />}</IconButton>
      </div>
    </div>
  );
};

export default Logo;
