import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const usePositionPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '200px',
      '& svg path': {
        stroke: theme.customPalette.darkBlue,
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: '25px',
        height: 'auto',
        marginBottom: theme.spacing(),
      },
    },
    flexRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    selectorRoot: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    selector: {
      width: '282px',
    },
    otherTextField: {
      marginTop: '40px',
      '& .MuiOutlinedInput-multiline': {
        borderRadius: '17.5px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '551px',
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiInputBase-root': {
          padding: '25px',
        },
      },
    },
  })
);
