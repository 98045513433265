import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '@fsl/fsl.common.npm';
import { Grid } from '@material-ui/core';
import { useMobileDeviceNavbarStyles } from '../../MobileDeviceNavbar.styles';
import { useCircleTileStyles } from './CircleTile.styles';
import { ReactComponent as ArrowIcon } from '../../../../../images/Pil.svg';
import Routing from '../../../../../routing/routing';
import { MyDistrictData } from '../../Dashboard.types';

interface ICircleTileProps {
  data?: MyDistrictData;
}

const CircleTile: FunctionComponent<ICircleTileProps> = ({ data = defaultData }) => {
  const navbarClasses = useMobileDeviceNavbarStyles();
  const tileClasses = useCircleTileStyles();
  const { translateString } = useTranslation();

  return (
    <NavLink to={Routing.school} className={`${navbarClasses.tile} ${tileClasses.root}`} exact>
      <Grid container spacing={1} className={navbarClasses.tilePadding}>
        <Grid item sm={12}>
          <div className={navbarClasses.tileText}>{translateString('my_district')}</div>
        </Grid>
        <Grid item sm={12}>
          {data?.districtNumber && (
            <>
              {translateString('district')} {data.districtNumber}
            </>
          )}
        </Grid>
        <Grid item sm={12}>
          {data?.memberTrustPositionsCount && (
            <>
              {data.memberTrustPositionsCount} {translateString('trusted_positions')}
            </>
          )}
        </Grid>
        <div className={navbarClasses.playIcon}>
          <ArrowIcon />
        </div>
      </Grid>
    </NavLink>
  );
};

const defaultData: MyDistrictData = {
  districtNumber: undefined,
  memberTrustPositionsCount: undefined,
};

export default CircleTile;
