import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import theme from '../../../theming/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
    },
    inlineContainer: {
      display: 'inline',
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.customPalette.blue,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export function ButtonProgressWrapper({ showProgress, children, inlineContainer }: PropsWithChildren<{ showProgress?: boolean; inlineContainer?: boolean }>) {
  const classes = useStyles();

  return (
    <span className={inlineContainer ? classes.inlineContainer : classes.container}>
      <span className={classes.wrapper}>
        {children}
        {showProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
      </span>
    </span>
  );
}
