import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import SimpleSelect from '../../../../../components/selectComponents/SimpleSelect/SimpleSelect';
import { usePositionPageStyles } from './EmploymentPage.styles';
import { useData } from './EmploymentPage.hooks';
import FslTextField from '../../../../../components/user-input/input/FslTextField/FslTextField';
import { useScreenSizeProvider } from '../../../../../hooks/useScreenSizeProvider';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import { useModal } from '../../../../../components/Modal/Modal.hooks';
import TermsAndConditions from './termsAndConditions/TermsAndConditions';

interface IEmploymentPageProps {
  onNext: () => void;
  onPrevious: () => void;
}

const EmploymentPage: FunctionComponent<IEmploymentPageProps> = ({ onNext, onPrevious }) => {
  const classes = usePositionPageStyles();
  const { screenSize } = useScreenSizeProvider();
  const { translateString } = useTranslation();
  const { openModal, isModalOpen, closeModal } = useModal();

  const { options, employmentChanged, data, showTextArea, employmentTextChanged, validator, isEulaAccepted, acceptEula } = useData();

  const next = useCallback(() => {
    validator.setShowErrors(true);
    if (!validator.anyHasErrors) {
      onNext();
    }
  }, [onNext, validator]);

  useEffect(() => {
    if (!isEulaAccepted) {
      openModal('', () => <TermsAndConditions acceptEula={acceptEula} />, { hideCloseButton: true });
    } else if (isModalOpen) {
      closeModal();
    }
  }, [isModalOpen, openModal, isEulaAccepted, acceptEula, closeModal]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.flexRoot}>
          <div className={classes.selectorRoot}>
            <div className={classes.selector}>
              <SimpleSelect
                autoFocus
                options={options}
                inputLabel={translateString('employment')}
                onChange={(e) => employmentChanged(e.target.value)}
                value={data.employment}
                error={validator.shouldShowErrors('employment')}
                helperText={validator.getErrorsForDisplay('employment')}
              />
            </div>
          </div>
          {showTextArea && (
            <div className={classes.otherTextField}>
              <FslTextField
                label={translateString('employment_situation_description')}
                multiline
                rowsMax={screenSize === 'SmallScreen' ? 2 : 8}
                value={data.employmentText}
                onChange={(text) => employmentTextChanged(text)}
                error={validator.shouldShowErrors('employmentText')}
                helperText={validator.getErrorsForDisplay('employmentText')}
              />
            </div>
          )}
        </div>
      </div>
      <ButtonGroup stepperProgress={0} next={next} previous={onPrevious} />
    </>
  );
};

export default EmploymentPage;
