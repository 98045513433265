import { useCallback, useContext } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import Routing from '../../../routing/routing';

export const useListMenuData = () => {
  const { state } = useContext(MessageContext);

  const isMessagesLinkActive = useCallback((match: any, location: { pathname: string }) => {
    return location && (location.pathname?.startsWith(Routing.messages) || location.pathname?.startsWith(Routing.newMessage));
  }, []);

  return { isMessagesLinkActive, numberOfUnreadMessages: state.numberOfUnreadMessages };
};
