import React, { FunctionComponent } from 'react';
import DesktopNavbar from './DesktopNavbar/DesktopNavbar';
import MobileDeviceNavbar from './MobileDeviceNavbar/MobileDeviceNavbar';

interface INavbarProps {
  smallScreen: boolean;
}

const Navbar: FunctionComponent<INavbarProps> = ({ smallScreen }) => {
  if (smallScreen) {
    return <MobileDeviceNavbar />;
  }

  return <DesktopNavbar />;
};

export default Navbar;
