import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { FslButton } from './components/buttons/FslButton/FslButton';
import { FullScreenCenter, FullScreenLoadingComponent } from './components/misc';
import config from './config';

export function AppPreload({ App }: { App: React.ComponentType<any> }) {
  const [loadState, setLoadState] = useState<string>('CheckingConnection');
  const [checkInBackground, setCheckInBackground] = useState(false);
  const [intervalToken, setIntervalToken] = useState<any>();
  const { translateString } = useTranslation();

  const checkConnection = async () => {
    const authPromise = fetch(`${config.authServer}/api/health`);
    const backendPromise = fetch(`${config.apiUrl}/health`);

    try {
      await authPromise;
    } catch {
      // Ignore backend promise since auth promise already failed
      backendPromise.catch(() => {});

      setLoadState('CannotReachAuthServer');
      setCheckInBackground(false);
      return;
    }

    try {
      await backendPromise;
    } catch {
      setLoadState('CannotReachApiServer');
      setCheckInBackground(false);
      return;
    }

    setLoadState('Ready');
  };

  useEffect(() => {
    if (loadState === 'CheckingConnection' || checkInBackground) {
      checkConnection();
    }

    if (loadState?.startsWith('CannotReach') && !checkInBackground) {
      const timeout = setTimeout(() => {
        setCheckInBackground(true);
      }, 3000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [loadState, checkInBackground]);

  useEffect(() => {
    if (loadState === 'Ready') {
      if (!intervalToken) {
        setIntervalToken(setInterval(() => checkConnection(), 60000));
      }
    } else {
      clearInterval(intervalToken);
      setIntervalToken(undefined);
    }

    return () => clearInterval(intervalToken);
  }, [intervalToken, loadState]);

  switch (loadState) {
    case 'CheckingConnection':
      return <FullScreenLoadingComponent />;

    case 'Ready':
      return <App />;

    default:
      if (/CannotReach.*/.test(loadState)) {
        const server = loadState === 'CannotReachAuthServer' ? 'login' : 'data';
        return (
          <FullScreenCenter>
            {translateString('no_connection_to_server', { server })}
            <br />
            <br />
            <br />
            <div style={{ height: 40 }}>
              {checkInBackground ? (
                <CircularProgress />
              ) : (
                <FslButton warn onClick={() => setLoadState('CheckingConnection')}>
                  {translateString('try_again')}
                </FslButton>
              )}
            </div>
          </FullScreenCenter>
        );
      }
      return (
        <FullScreenCenter>
          {translateString('unexpected_error')}
          <Button onClick={() => setLoadState('CheckingConnection')}>{translateString('try_again')}</Button>
        </FullScreenCenter>
      );
  }
}
