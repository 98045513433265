import { Slide } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import FadeComponent from '../FadeComponent/FadeComponent';

export type SlideDirection = 'left' | 'right' | 'up' | 'down' | undefined;

interface ISlideComponentProps {
  slideDirection: SlideDirection;
  contentClassname?: string;
}

const SlideComponent: FunctionComponent<ISlideComponentProps> = ({ children, slideDirection, contentClassname }) => {
  if (slideDirection) {
    return (
      <Slide in direction={slideDirection} timeout={500}>
        <div className={contentClassname ?? ''}>{children}</div>
      </Slide>
    );
  }

  return (
    <FadeComponent>
      <div>{children}</div>
    </FadeComponent>
  );
};

export default SlideComponent;
