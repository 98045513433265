import { OptionsObject, SnackbarAction, useSnackbar, VariantType } from 'notistack';
import { ReactNode } from 'react';

export type FslSnackbarOptions = { autoHideDuration?: number } & { action?: SnackbarAction };

export const useFslSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const parseSnackbarAction = (options?: FslSnackbarOptions): SnackbarAction | undefined => {
    if (options) {
      if ('action' in options && options.action) {
        return options.action;
      }
    }

    return undefined;
  };

  const parseSnackbarOptions = (variant: VariantType, options?: FslSnackbarOptions): OptionsObject => {
    return {
      variant,
      autoHideDuration: options?.autoHideDuration ?? 5000,
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      onClick: () => closeSnackbar(),
      action: parseSnackbarAction(options),
    };
  };

  const successSnackbar = (content: ReactNode, options?: FslSnackbarOptions) => {
    enqueueSnackbar(content, parseSnackbarOptions('success', options));
  };

  const failureSnackbar = (content: ReactNode, options?: FslSnackbarOptions & { logError?: any }) => {
    enqueueSnackbar(content, parseSnackbarOptions('error', options));
  };

  return { successSnackbar, failureSnackbar, enqueueSnackbar, closeSnackbar };
};
