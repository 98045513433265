export const formatSsn = (ssn: string | undefined): string => {
  return ssn && ssn.length > 7 ? `${ssn.substr(0, 6)} - ${ssn.substr(6)}` : '';
};

export const validatorMessageTranslate = (translateString: (k: string, options?: any) => string, key: string, options?: any) => () => {
  const translatedOptions: any = {};

  if (options) {
    Object.keys(options)
      .filter((k) => k)
      .forEach((k) => {
        translatedOptions[k] = translateString(options[k]);
      });
  }

  return translateString(key, translatedOptions);
};
