import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Routing from '../../routing/routing';

interface IContentSwitchProps {
  homeComponent: FunctionComponent;
}

const ContentSwitch: FunctionComponent<IContentSwitchProps> = ({ homeComponent }) => {
  const LazyMembershipPage = lazy(() => import('../../pages/MembershipPage/MembershipPage'));
  const LazyCoursesPage = lazy(() => import('../../pages/CoursePage/CoursePage'));
  const LazyMyDistrictPage = lazy(() => import('../../pages/myDistrictPage/myDistrictPage'));
  const LazyMySchoolPage = lazy(() => import('../../pages/MySchoolPage/MySchoolPage'));
  const LazyMessagePage = lazy(() => import('../../pages/MessagePage/MessagePage'));
  const LazyCasesPage = lazy(() => import('../../pages/MyCasesPage/MyCasesPage'));
  const LazyNewMessagePage = lazy(() => import('../../pages/NewMessagePage/NewMessagePage'));

  return (
    <Suspense fallback="">
      <Switch>
        <Route path="/" component={homeComponent} exact />
        <Route path={Routing.membership} component={LazyMembershipPage} exact />
        <Route path={Routing.courses} component={LazyCoursesPage} exact />
        <Route path={Routing.district} component={LazyMyDistrictPage} exact />
        <Route path={Routing.school} component={LazyMySchoolPage} exact />
        <Route path={Routing.messages} component={LazyMessagePage} exact />
        <Route path={`${Routing.reply}/:id?`} component={LazyNewMessagePage} exact />
        <Route path={`${Routing.newMessage}/:id?`} component={LazyNewMessagePage} exact />
        <Route path={Routing.cases} component={LazyCasesPage} exact />
      </Switch>
    </Suspense>
  );
};

export default ContentSwitch;
