import { IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import React, { Component } from 'react';

interface IErrorBoundaryProps {
  logException: (error: IExceptionTelemetry) => void;
  primaryMessage: string;
  secondaryMesage: string;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    this.props.logException({ exception: error, severityLevel: SeverityLevel.Critical });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>{this.props.primaryMessage}</h1>
          <h2>{this.props.secondaryMesage}</h2>
        </>
      );
    }

    return this.props.children;
  }
}
