import { useTranslation } from '@fsl/fsl.common.npm';
import { useLocation } from 'react-router-dom';
import { useScreenSizeProvider } from '../../../../hooks/useScreenSizeProvider';
import Routing from '../../../../routing/routing';

export const usePathname = () => {
  const { screenSize } = useScreenSizeProvider();
  const { translateString } = useTranslation();
  const { pathname } = useLocation();

  let logoSubtitle = translateString('logo_title');
  if (screenSize === 'SmallScreen') {
    switch (pathname) {
      case Routing.district:
        logoSubtitle = translateString('my_district');
        break;
      case Routing.membership:
        logoSubtitle = translateString('my_membership');
        break;
      case Routing.school:
        logoSubtitle = translateString('my_school');
        break;
      default: {
        logoSubtitle = translateString('logo_title');
      }
    }
  }

  return { logoSubtitle };
};
