import { CircularProgress } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { CenterContent } from '../../misc';

export interface IProgressSpinnerProps {
  style?: CSSProperties;
  className?: string;
}

export function ProgressSpinner({ style, className }: IProgressSpinnerProps) {
  return (
    <CenterContent style={style} className={className}>
      <CircularProgress />
    </CenterContent>
  );
}

export function InlineSpinner({ style, className }: IProgressSpinnerProps) {
  return <CircularProgress size={10} style={style} className={className} />;
}
