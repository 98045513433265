import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        flexShrink: 0,
      },
      '& > :global(#scroll)': {
        flexShrink: 'unset',
        overflowY: 'auto',
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderRadius: 0,
      outline: 'none',
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '80%',
      },
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    close: {
      color: theme.customPalette.red,
      cursor: 'pointer',
      height: '28px',
      width: '28px',
    },
    heading: {
      textAlign: 'center',
      display: 'block',
      width: '100%',
      color: theme.customPalette.darkBlue,
      fontWeight: 900,
    },
    closeButtonWrapper: {
      '& .MuiIconButton-root': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          paddingBottom: '12px',
          paddingLeft: '12px',
        },
      },
    },
  })
);
