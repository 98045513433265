import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useFinalPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '600px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
    buttonSection: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '50px',
    },
  })
);
