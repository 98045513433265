import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const usePanelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '37px 30px',
      overflow: 'hidden',
    },
    accordionRoot: {
      padding: '14px 30px 30px 30px',
      minHeight: '0',
      '& .MuiAccordionSummary-content': {
        margin: '0',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      textTransform: 'uppercase',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: '16px',
      marginTop: '0',
    },
    accordionTitle: {
      margin: '0',
    },
    accordionIcon: {
      fill: theme.customPalette.red,
    },
    accordionBar: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '5px',
      },
    },
  })
);
