import { Fade } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface IFadeComponentsProps {
  children: ReactElement<any, any> | undefined;
}

const FadeComponent = ({ children }: IFadeComponentsProps) => {
  return (
    <Fade in timeout={500}>
      {children}
    </Fade>
  );
};

export default FadeComponent;
