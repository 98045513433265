import { Grid, Paper } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import ContentWrapper from '../../layout/ContentWrapper/ContentWrapper';
import NewMemberFlow from './newMemberFlow/NewMemberFlow';
import { useNewMembershipPageStyles } from './NewMembershipPage.styles';
import { NewMemberProvider } from './newMemberContext';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { ReactComponent as LogoIcon } from '../../images/Cirkel_to_farver-01.svg';

const NewMembershipPage: FunctionComponent<{ step?: number }> = ({ step, children }) => {
  const classes = useNewMembershipPageStyles();
  const { translateString } = useTranslation();

  return (
    <NewMemberProvider>
      <div className={classes.root}>
        <div className={classes.sidepanel}>
          <LogoIcon />
        </div>
        <ContentWrapper title={{ text: translateString('sign_up'), showOnMobile: true }} rightCornerComponent={<LanguageSelector />}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>{children || <NewMemberFlow step={step} />}</Paper>
            </Grid>
          </Grid>
        </ContentWrapper>
      </div>
    </NewMemberProvider>
  );
};

export default NewMembershipPage;
