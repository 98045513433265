import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { Ref } from 'react';
import { ButtonProgressWrapper } from './ButtonProgressWrapper';
import Theme from '../../../theming/theme';

export type IFslButtonProps = Omit<ButtonProps, 'variant' | 'color'> & {
  showProgress?: boolean;
  warn?: boolean;
  ok?: boolean;
  secondary?: boolean;
  link?: boolean;
  icon?: string;
  buttonRef?: Ref<HTMLButtonElement>;
};

export function FslButton({ showProgress, disabled, warn, ok, secondary, link, icon, children, onClick, buttonRef, ...props }: IFslButtonProps) {
  let variant: 'text' | 'outlined' | 'contained' = 'outlined';
  if (warn || ok) {
    variant = 'contained';
  } else if (link) {
    variant = 'text';
  }

  const color = warn || (!ok && !secondary) ? 'primary' : 'secondary';

  const buttonStyle: React.CSSProperties =
    variant === 'text' ? { ...props.style, display: 'flex', color: Theme.customPalette.red, textDecoration: 'none', fontWeight: 'bold' } : { ...props.style };

  return (
    <ButtonProgressWrapper showProgress={showProgress} inlineContainer={link}>
      <Button {...props} ref={buttonRef} style={buttonStyle} variant={variant} color={color} disabled={showProgress || disabled} onClick={onClick}>
        {children}
      </Button>
    </ButtonProgressWrapper>
  );
}
