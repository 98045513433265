import { useCallback, useMemo } from 'react';
import { get } from '../api';

export const useTermsAndConditionsEndpoint = (language: string) => {
  const endpoint = useMemo(() => `/terms-and-conditions/${language}`, [language]);

  const fetchPdf = useCallback(async () => {
    return get(endpoint, { ignoreAuthentication: true }).then((response) => response.json());
  }, [endpoint]);

  return { fetchPdf, endpoint };
};
