import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '@fsl/fsl.common.npm';
import { Grid } from '@material-ui/core';
import Routing from '../../../../../routing/routing';
import { useMobileDeviceNavbarStyles } from '../../MobileDeviceNavbar.styles';
import { ReactComponent as ArrowIcon } from '../../../../../images/Pil.svg';
import { useCasesTileStyles } from './CasesTile.styles';

const CasesTile: FunctionComponent = () => {
  const navbarClasses = useMobileDeviceNavbarStyles();
  const tileClasses = useCasesTileStyles();
  const { translateString } = useTranslation();

  return (
    <NavLink to={Routing.messages} className={`${navbarClasses.tile} ${tileClasses.root}`} exact>
      <Grid container spacing={1} className={navbarClasses.tilePadding}>
        <Grid item sm={12}>
          <div className={navbarClasses.tileText}>{translateString('my_cases')}</div>
        </Grid>
        <div className={navbarClasses.playIcon} style={{ position: 'absolute', right: 10, bottom: -10 }}>
          <ArrowIcon />
        </div>
      </Grid>
    </NavLink>
  );
};

export default CasesTile;
