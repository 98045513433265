import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useCircleTileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.white,
      color: theme.customPalette.black,
      marginRight: theme.spacing(),
    },
    icon: {
      paddingTop: '5px',
      marginBottom: '-50px',
    },
  })
);
