import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useLogoStyles = makeStyles<Theme, { showBottomLine: boolean }>((theme: Theme) =>
  createStyles({
    root: (props) => ({
      display: 'flex',
      marginTop: '30px',
      paddingLeft: theme.spacing(),
      paddingBottom: theme.spacing(),
      borderBottom: props.showBottomLine ? `1px solid ${theme.customPalette.red}` : 'none',
    }),
    logoText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: theme.spacing(),
      width: '100%',
    },
    header: {
      fontWeight: theme.fontWeight,
    },
    menu: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      paddingRight: theme.spacing(),

      '& svg': {
        height: '40px',
        width: '40px',
      },
      '& path': {
        fill: theme.customPalette.red,
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  })
);
