import { useValidator, useTranslation } from '@fsl/fsl.common.npm';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ISchoolDropDownItem } from '../../../../../components/user-input/picker/SchoolPicker/SchoolPicker.types';
import { useJobTitlesEndpoint } from '../../../../../hooks/useJobTitlesEndpoint';
import { useSchoolNameEndpoint } from '../../../../../hooks/useSchoolNameEndpoint';
import { NewMemberContext } from '../../../newMemberContext';
import { EditablePositionData } from './PositionPage.types';

export const useData = (position: Partial<EditablePositionData>, index: number) => {
  const { translateString } = useTranslation();
  const { dispatch, state } = useContext(NewMemberContext);
  const { fetchSchoolNames } = useSchoolNameEndpoint();
  const { fetchJobTitles } = useJobTitlesEndpoint();

  const validator = useValidator<Partial<EditablePositionData>>(() => ({
    startDate: (p) => {
      if (!p.startDate) {
        return `${translateString('cannot_be_empty', { field: translateString('employment_start') })}`;
      }
      return undefined;
    },
    position: (p) => {
      if (!p.position || p.position.displayValue === '') {
        return `${translateString('cannot_be_empty', { field: translateString('position') })}`;
      }

      return undefined;
    },
    employmentRate: (p) => {
      if (!p.employmentRate || p.employmentRate === '1') {
        return `${translateString('cannot_be_empty', { field: translateString('employment_percent') })}`;
      }
      return undefined;
    },
    schoolNameNotInList: (t) => {
      if (t.workplaceNotInList && (t.schoolNameNotInList === undefined || t.schoolNameNotInList === '')) {
        return `${translateString('cannot_be_empty', { field: translateString('workplace_name') })}`;
      }
      return undefined;
    },
    positionDescription: (t) => {
      if (t.jobTitleNotInList && (t.positionDescription === undefined || t.positionDescription === '')) {
        return `${translateString('cannot_be_empty', { field: translateString('describe_position') })}`;
      }
      return undefined;
    },
    school: (t) => {
      if (!t.workplaceNotInList && !t.school) {
        return `${translateString('cannot_be_empty', { field: translateString('school') })}`;
      }
      if (!t.workplaceNotInList && !t.school) {
        return `${translateString('cannot_be_empty', { field: translateString('school') })}`;
      }

      return undefined;
    },
  }));

  const updatePosition = useCallback(
    (p: Partial<EditablePositionData>) => {
      validator.showErrorsFor(p);
      dispatch({ type: 'updatePositions', position: p, index });
    },
    [dispatch, validator, index]
  );

  const rateOptions = useMemo(() => {
    return [
      { id: 1, displayValue: '' },
      { id: 2, displayValue: translateString('above_percent', { percent: 51 }) },
      { id: 3, displayValue: translateString('below_percent', { percent: 50 }) },
    ];
  }, [translateString]);

  useEffect(() => {
    const fetch = async () => {
      const positions = await fetchJobTitles();
      const options = positions.map((p, i) => ({ id: i + 2, guid: p.id, displayValue: p.title }));
      options.sort((a, b) => {
        const indexA = ['Andet'].indexOf(a.displayValue);
        const indexB = ['Andet'].indexOf(b.displayValue);
        if (indexA === -1 && indexB === -1) {
          return a.displayValue.localeCompare(b.displayValue, 'da');
        }
        return indexA - indexB;
      });
      const jobTitles = [{ id: 0, guid: null, displayValue: '' }, ...options];
      dispatch({ type: 'setJobTitles', jobTitles });
    };

    if (!state.jobTitles) {
      fetch();
    }
  }, [dispatch, state.jobTitles, translateString, fetchJobTitles]);

  const onSchoolChanged = useCallback(
    (changes: ISchoolDropDownItem | undefined) => {
      updatePosition({ school: changes });
    },
    [updatePosition]
  );

  const fetchSchools = useCallback(
    async (q: string) => {
      const schools = await fetchSchoolNames(q);
      const getAddressString = (addr: string | undefined) => (addr ? `, ${addr}` : '');
      const getPostalCode = (code: string | undefined) => (code ? `, ${code}` : '');
      const getCity = (city: string | undefined) => (city ? ` ${city}` : '');
      const getSchoolType = (schoolType: string | undefined) => (schoolType ? `, ${schoolType}` : '');
      return schools.map((s) => ({
        id: s.id,
        name: `${s.name}${getAddressString(s.address)}${getPostalCode(s.postalCode)}${getCity(s.city)}${getSchoolType(s.schoolType)}`,
      }));
    },
    [fetchSchoolNames]
  );

  const onPositionChanged = useCallback(
    (id: string) => {
      const selectedJobTitle = state.jobTitles?.find((p) => p.id === Number(id));
      if (selectedJobTitle) {
        const jobTitleNotInList = selectedJobTitle.id === 1;
        updatePosition({ position: selectedJobTitle, jobTitleNotInList });
      }
    },
    [updatePosition, state.jobTitles]
  );

  const selectedPosition = useMemo(() => {
    return state.positions[index]?.position;
  }, [index, state.positions]);

  const selectedSchool = useMemo(() => {
    return state.positions[index]?.school;
  }, [index, state.positions]);

  validator.check(position);

  return {
    updatePosition,
    rateOptions,
    validator,
    onSchoolChanged,
    fetchSchools,
    onPositionChanged,
    positionOptions: state.jobTitles,
    selectedPosition,
    selectedSchool,
  };
};
