import { createStyles, makeStyles } from '@material-ui/core';

export const useIconButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      background: 'transparent',
      border: 'none',
      outline: 'none',
      '&:hover': { cursor: 'pointer' },
    },
    popover: {
      padding: '20px',
    },
    popoverContent: {
      display: 'flex',
      marginTop: '10px',
    },
    buttonDivider: {
      width: '15px',
    },
  })
);
