import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useAcknowledgePageStyles } from './AcknowledgePage.styles';

interface ISectionProps {
  title: string;
  texts: (string | undefined)[];
}

const Section: FunctionComponent<ISectionProps> = ({ title, texts }) => {
  const classes = useAcknowledgePageStyles();

  return (
    <>
      <Grid item xs={12} sm={6} className={classes.headerGrid}>
        <Typography variant="body1" className={classes.header}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {texts
          .filter((t) => t !== undefined)
          .map((t, index) => {
            if (t === '') {
              // eslint-disable-next-line react/no-array-index-key
              return <div key={index} className={classes.textSeparator} />;
            }
            // eslint-disable-next-line react/no-array-index-key
            return <Typography key={index}>{t}</Typography>;
          })}
      </Grid>
    </>
  );
};

export default Section;
