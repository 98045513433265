import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useTermsAndConditionsStyles = makeStyles<Theme, { windowWidth: number }>((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: '15px',
      width: (props) => `${props.windowWidth}px`,
      '& .MuiAccordionSummary-root': {
        display: 'inline-flex',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80% !important',
      },
    },
    accordionIcon: {
      fill: theme.customPalette.red,
    },
    accordionRoot: {
      minHeight: '0',
      '& .MuiAccordionSummary-content': {
        margin: '0',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0',
      },
      '& .react-pdf__Document': {
        height: '100% !important',
        width: '100% !important',
      },
      '& .react-pdf__Page__canvas': {
        height: '100% !important',
        width: '100% !important',
      },
    },
    logo: {
      margin: '30px 0',
    },
    conditions: {
      textTransform: 'uppercase',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '30px',
      '& button': {
        minWidth: '225px',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'Column',
      },
    },
    firstButton: {
      marginRight: '15px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '15px',
      },
    },
    topbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    downloadLink: {
      marginTop: '15px',
      '& a': {
        textDecoration: 'none',
        color: '#112F4A',
      },
    },
    page: {
      border: `1px solid ${theme.customPalette.grey}`,
      marginBottom: '5px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '10px',
      whiteSpace: 'pre-wrap',
    },
    subheader: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '15px',
      fontWeight: 800,
    },
  })
);
