import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useMenuItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      fontSize: '14px',
      marginBottom: theme.spacing(),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(),
      textTransform: 'uppercase',
    },
    icon: {
      width: '20px',
      height: '20px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      textDecoration: 'none',
      padding: `${theme.spacing()}px ${theme.spacing()}px 0 ${theme.spacing()}px`,
      color: theme.customPalette.darkBlue,
      '&.active': {
        fontWeight: theme.fontWeight,
      },
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing()}px ${theme.spacing()}px 0 ${theme.spacing() * 2}px`,
      },
    },
    counter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.customPalette.red,
      color: theme.customPalette.white,
      fontSize: '10px',
      borderRadius: '20px',
      width: '20px',
      height: '20px',
      marginLeft: '5px',
    },
  })
);
