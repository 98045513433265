import { FormControl, InputLabel, NativeSelect, NativeSelectProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ReactComponent as DropdownIcon } from '../../../images/dropdown.svg';
import { useSimpleSelectStyle } from './SimpleSelect.styles';

interface ISimpleSelectProps {
  options: { id: number | string; displayValue: string }[];
  inputLabel: string;
  helperText?: string;
}

const SimpleSelect: FunctionComponent<ISimpleSelectProps & NativeSelectProps> = ({ options, inputLabel, helperText, ...rest }) => {
  const classes = useSimpleSelectStyle();

  return (
    <FormControl>
      <InputLabel>{inputLabel}</InputLabel>
      <NativeSelect {...rest} className={classes.nativeSelect} IconComponent={DropdownIcon}>
        {options.map((o) => (
          <option key={o.id} value={o.id}>
            {o.displayValue}
          </option>
        ))}
      </NativeSelect>
      {helperText && <p className={classes.helperText}>{helperText}</p>}
    </FormControl>
  );
};

export default SimpleSelect;
