import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { SingleValidator } from '@fsl/fsl.common.npm/build/validation/SingleValidator';
import FslTextField from '../../../../../components/user-input/input/FslTextField/FslTextField';
import { EditablePositionData } from './PositionPage.types';

interface IFreeTextSchoolPositionProps {
  showSchoolTextField: boolean;
  showPositionTextField: boolean;
  position: Partial<EditablePositionData>;
  updatePosition: (p: Partial<EditablePositionData>) => void;
  validator: SingleValidator<Partial<EditablePositionData>, Partial<EditablePositionData>>;
}

const FreeTextSchoolPosition: FunctionComponent<IFreeTextSchoolPositionProps> = ({
  showSchoolTextField,
  showPositionTextField,
  position,
  updatePosition,
  validator,
}) => {
  const { translateString } = useTranslation();
  if (!showSchoolTextField && !showPositionTextField) {
    return null;
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        {showSchoolTextField && (
          <FslTextField
            value={position.schoolNameNotInList}
            onChange={(v) => updatePosition({ school: undefined, schoolNameNotInList: v })}
            label={translateString('workplace_name')}
            title={translateString('workplace_name')}
            error={validator.shouldShowErrors('schoolNameNotInList')}
            helperText={validator.getErrorsForDisplay('schoolNameNotInList')}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {showPositionTextField && (
          <FslTextField
            value={position.positionDescription}
            onChange={(v) => updatePosition({ positionDescription: v })}
            label={translateString('describe_position')}
            title={translateString('describe_position')}
            error={validator.shouldShowErrors('positionDescription')}
            helperText={validator.getErrorsForDisplay('positionDescription')}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FreeTextSchoolPosition;
