import { UserSelectionState, UserSelectionAction } from './UserSelectionContext.types';

export default (state: UserSelectionState, action: UserSelectionAction): UserSelectionState => {
  switch (action.type) {
    case 'setSelectedSchoolId': {
      return { ...state, selectedSchoolId: action.schooldId };
    }
    case 'setSelectedDistrictId': {
      return { ...state, selectedDistrictId: action.districtId };
    }
    case 'setSelectedInboxPage': {
      return { ...state, selectedInboxPage: action.inboxPage };
    }
    case 'setSelectedSentMessagesPage': {
      return { ...state, selectedSentMessagesPage: action.sentMessagesPage };
    }
    default: {
      return state;
    }
  }
};
