import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useFlowButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiButton-root': {
        borderRadius: '20px',
        fontWeight: theme.fontWeight,
      },
      '& .MuiButton-root:hover': {
        color: theme.customPalette.darkBlue,
      },
      '& .MuiButton-containedSecondary': {
        backgroundColor: theme.customPalette.darkBlue,
      },
      '& .MuiButton-contained.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `2px solid ${theme.customPalette.grey}`,
        color: theme.customPalette.grey,
        fontWeight: theme.fontWeight,
        height: '33px',
      },
    },
  })
);
