import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React, { FunctionComponent, useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '@fsl/fsl.common.npm';
import { Document, pdfjs, Page } from 'react-pdf';
import { useTermsAndConditionsStyles } from './TermsAndConditions.styles';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import { ReactComponent as LogoIcon } from '../../../../../../images/Cirkel_to_farver-01.svg';
import { FslButton } from '../../../../../../components/buttons/FslButton/FslButton';
import FlowButton from '../../../components/FlowButton/FlowButton';
import { useData } from './TermsAndConditions.hooks';
import LanguageSelector from '../../../../../../components/LanguageSelector/LanguageSelector';
import { useScreenSizeProvider } from '../../../../../../hooks/useScreenSizeProvider';
import { ProgressSpinner } from '../../../../../../components/feedback/ProgressSpinner/ProgressSpinner';

interface ITermsAndConditionsProps {
  acceptEula: (eulaName: string | undefined) => void;
}

const TermsAndConditions: FunctionComponent<ITermsAndConditionsProps> = ({ acceptEula }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [expanded, setExpanded] = useState(false);
  const [pageAmount, setPageAmount] = useState<number>();

  const { windowWidth, windowHeight } = useWindowDimensions();

  const contentWidth = useMemo(() => {
    return windowWidth * 0.5;
  }, [windowWidth]);

  const contentHeight = useMemo(() => {
    return windowHeight * 0.8;
  }, [windowHeight]);

  const { translateString, language } = useTranslation();
  const { eula } = useData(language);
  const classes = useTermsAndConditionsStyles({ windowWidth: contentWidth });
  const { screenSize } = useScreenSizeProvider();

  const pages = useMemo(() => {
    const p: any[] = [];
    for (let i = 0; i < (pageAmount ?? 0); i++) {
      p.push(
        <div key={i} className={classes.page}>
          <Page width={contentWidth} height={contentHeight} pageNumber={i + 1} />
        </div>
      );
    }
    return p;
  }, [pageAmount, contentWidth, contentHeight, classes]);

  return (
    <div className={classes.root} style={{ height: expanded ? `${contentHeight}px` : '500px' }}>
      <div className={classes.topbar}>
        <LogoIcon className={classes.logo} />
        <LanguageSelector />
      </div>
      <div style={{ paddingBottom: '15px' }}>
        <Typography variant="h6" className={classes.header}>
          {translateString('terms_header')}
        </Typography>
        <Typography variant="h6" className={classes.subheader}>
          {translateString('terms_subheader')}
        </Typography>
        <Typography variant="body1">{translateString('terms_text')}</Typography>
        {!eula?.name ? (
          <ProgressSpinner />
        ) : (
          <>
            {' '}
            {screenSize === 'SmallScreen' && eula && (
              <div className={classes.downloadLink}>
                <a download={eula?.name} href={eula ? `data:application/pdf;base64,${eula?.eula}` : ''}>
                  <Typography variant="h6" className={classes.conditions}>
                    {translateString('terms_general')}
                  </Typography>
                </a>
              </div>
            )}
            {screenSize === 'LargeScreen' && (
              <div className={classes.accordionRoot}>
                <Accordion
                  TransitionProps={{
                    timeout: 0,
                  }}
                  variant="elevation"
                  expanded={expanded}
                  onChange={() => setExpanded((state) => !state)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}>
                    <Typography variant="h6" className={classes.conditions}>
                      {translateString('terms_general')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {eula && eula.eula && (
                      <Document file={`data:application/pdf;base64,${eula.eula}`} onLoadSuccess={({ numPages }) => setPageAmount(numPages)}>
                        {pages}
                      </Document>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            <div className={classes.buttons}>
              <div className={classes.firstButton}>
                <FlowButton ok onClick={() => window.location.replace('https://www.fsl.dk/')}>
                  {translateString('eula_not_accept')}
                </FlowButton>
              </div>
              <FslButton warn onClick={() => acceptEula(eula?.name)}>
                {translateString('eula_accept')}
              </FslButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
