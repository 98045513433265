import { MutableRefObject } from 'react';

export function delay(delayInMillis: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delayInMillis);
  });
}

export function mergeRefs<T>(...refs: React.Ref<T>[]): React.Ref<T> {
  const filteredRefs = refs.concat();

  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];

  return (inst: T) => {
    filteredRefs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        (ref as MutableRefObject<T>).current = inst;
      }
    });
  };
}

export function makeKeysUpperCase<T>(obj: Record<string, T>): Record<string, T> {
  const result: Record<string, T> = {};

  Object.entries(obj).forEach(([key, value]) => {
    result[key.toUpperCase()] = value;
  });

  return result;
}

export interface IDictionary<T> {
  lookup(key: string, defaultValue?: T | undefined): T | undefined;
  lookup(key: string, defaultValue?: () => T | undefined): T | undefined;
}

export function toDictionary<T>(obj: Record<string, T>): IDictionary<T> {
  obj = makeKeysUpperCase(obj);

  return {
    lookup: (key: string, defaultValue?: T | (() => T | undefined)) => {
      const value = obj[`${key}`.toUpperCase()];

      if (value) return value;
      if (!defaultValue) return undefined;

      if (defaultValue instanceof Function) return defaultValue();

      return defaultValue;
    },
  };
}

type Defined<T> = Exclude<T, undefined | null>;
export function toArray<T extends Exclude<any, undefined | null>>(value?: T | T[] | undefined | null): Defined<T>[] {
  if (!value) return [];

  if (Array.isArray(value)) return value.filter((v) => !!v) as Defined<T>[];

  return [value as Defined<T>];
}

export function makeRegExpCaseInsensitive(regexp: RegExp): RegExp {
  if (regexp.ignoreCase) {
    return regexp;
  }

  return new RegExp(regexp.source, `${regexp.flags}i`);
}

export function isValidDate(d: Date) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(d.getTime());
}

export const sortDateAsc = (a: Date | null | undefined, b: Date | null | undefined) => {
  if (a && b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
  if (!a) return -1;
  if (!b) return 1;
  return 0;
};

export const sortDateDesc = (a: Date | null | undefined, b: Date | null | undefined) => {
  return sortDateAsc(a, b) * -1;
};
