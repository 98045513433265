import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { userManager } from '../../auth';
import { useMessagesEndpoint } from '../../hooks/useMessagesEndpoint';
import routing from '../../routing/routing';
import { Recipient } from '../../contexts/MessageContext/MessageContext.types';

const FslCallbackPage: FunctionComponent = () => {
  const { postDraftMessage } = useMessagesEndpoint();
  const { push } = useHistory();
  userManager
    .signinRedirectCallback()
    .then((user) => {
      if (user?.state) {
        const recipient: Recipient = {
          memberId: user.state,
        };
        postDraftMessage('', '', false, [recipient], undefined)
          .then((response) => {
            push(`${routing.newMessage}/${response}`);
          })
          .catch(() => {});
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}${routing.messages}/`;
      }
    })
    .catch(() => {});
  return <></>;
};

export default FslCallbackPage;
