import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '@fsl/fsl.common.npm';
import clsx from 'clsx';
import { useMobileDeviceNavbarStyles } from '../../MobileDeviceNavbar.styles';
import { useMembershipTileStyles } from './MembershipTile.styles';
import { ReactComponent as ArrowIcon } from '../../../../../images/Pil.svg';
import Routing from '../../../../../routing/routing';
import LabelValueComponent from '../../../../../components/LabelValueComponent/LabelValueComponent';
import { MyMembershipData } from '../../Dashboard.types';
import { useMemberStatusFormatter } from '../../../../../pages/MembershipPage/panels/MyInformation/MyInformation.hooks';
import theme from '../../../../../theming/theme';
import { useMemberTypeFormatter } from '../../../../../hooks/useMemberTypeFormatter';
import { useMySubscriptionStyles } from '../../../../../pages/MembershipPage/panels/MySubscription/MySubscription.styles';

interface IMembershipTileProps {
  data?: MyMembershipData;
}
const MembershipTile: FunctionComponent<IMembershipTileProps> = ({ data = defaultData }) => {
  const navbarClasses = useMobileDeviceNavbarStyles();
  const tileClasses = useMembershipTileStyles();
  const { translateString } = useTranslation();
  const { memberStatusColor, memberStatusText } = useMemberStatusFormatter(data.memberStatus ?? 'Unknown', theme);
  const { formatMemberType } = useMemberTypeFormatter();
  const subscriptionClasses = useMySubscriptionStyles();
  const valueClassName = data.negativeRate
    ? clsx(subscriptionClasses.sidePanelValue, subscriptionClasses.sidePanelValueIsNegative)
    : subscriptionClasses.sidePanelValue;

  return (
    <NavLink to={Routing.membership} className={`${navbarClasses.tile} ${tileClasses.root}`} exact>
      <Grid container spacing={1} className={navbarClasses.tilePadding}>
        <Grid item sm={12}>
          <div className={navbarClasses.tileText}>{translateString('my_membership')}</div>
        </Grid>
        {data?.positions && data?.positions.length > 0 && (
          <Grid item sm={6}>
            <LabelValueComponent label={translateString('trusted_positions')} value={data.positions} />
          </Grid>
        )}
        <Grid item sm={6}>
          <LabelValueComponent label={translateString('member_type')} value={formatMemberType(data.memberType)} />
        </Grid>
        <Grid item sm={6}>
          <LabelValueComponent label={translateString('member_status')} value={memberStatusText ?? ''} showDot={{ circleColor: memberStatusColor }} />
        </Grid>
        <Grid item sm={6}>
          <LabelValueComponent label={translateString('your_balance')} value="" />
          <div className={subscriptionClasses.sidePanelValuePair}>
            <Typography variant="body1" className={valueClassName}>
              {data.balance} kr
            </Typography>
          </div>
        </Grid>
        <div className={navbarClasses.playIcon}>
          <ArrowIcon />
        </div>
      </Grid>
    </NavLink>
  );
};

const defaultData: MyMembershipData = {
  balance: undefined,
  memberFrom: '',
  memberStatus: 'Unknown',
  memberType: 'Other',
  membernumber: '',
  negativeRate: false,
};

export default MembershipTile;
