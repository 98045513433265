import React, { FunctionComponent } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { useMobileDeviceNavbarStyles } from '../../MobileDeviceNavbar.styles';
import { useWelcomeTileStyles } from './WelcomeTile.styles';

import { ReactComponent as WelcomeIcon } from '../../../../../images/Able_forsiden_cropped.svg';

interface IWelcomeTileProps {
  fullName?: string;
  memberNumber?: number | undefined;
}

const WelcomeTile: FunctionComponent<IWelcomeTileProps> = ({ fullName, memberNumber }) => {
  const navbarClasses = useMobileDeviceNavbarStyles();
  const tileClasses = useWelcomeTileStyles();
  const { translateString } = useTranslation();

  return (
    <div className={`${navbarClasses.tile} ${tileClasses.root}`}>
      <div className={tileClasses.textContainer}>
        <div className={tileClasses.header}>
          <span>{translateString('welcome_user', { user: fullName })}</span>
        </div>
        <div className={tileClasses.text}>
          <span>{`${translateString('member_number')}: ${memberNumber}`}</span>
        </div>
      </div>
      <div className={tileClasses.icon}>
        <WelcomeIcon />
      </div>
    </div>
  );
};

export default WelcomeTile;
