import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { useButtonGroupStyles } from './ButtonGroup.styles';
import FlowButton from '../FlowButton/FlowButton';

interface IButtonGroup {
  stepperProgress: number;
  next: () => void;
  previous: () => void;
}

const ButtonGroup: FunctionComponent<IButtonGroup> = ({ stepperProgress, next, previous }) => {
  const classes = useButtonGroupStyles();
  const { translateString } = useTranslation();

  const buttonText = useMemo(() => {
    return stepperProgress === 3 ? translateString('send') : translateString('next');
  }, [stepperProgress, translateString]);

  return (
    <div className={classes.root}>
      <FlowButton ok disabled={stepperProgress <= 0} onClick={previous}>
        {translateString('previous')}
      </FlowButton>
      <div className={classes.buttonSeparator} />

      <FlowButton ok onClick={next}>
        {buttonText}
      </FlowButton>
    </div>
  );
};

export default ButtonGroup;
