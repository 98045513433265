const frontpage = '/';
const courses = '/kurser';
const membership = '/medlemskab';
const school = '/skole';
const district = '/kreds';
const logout = '/logout';
const newMember = '/nytmedlem';
const messages = '/beskeder';
const newMessage = '/nybesked';
const reply = '/besvar';
const mailValidation = '/verifyemail';
const fslAutoRedirectLogin = '/fslAutoRedirect';
const fslCallback = '/fslcallback';
const cases = '/cases';

const Routing = {
  frontpage,
  courses,
  membership,
  school,
  district,
  logout,
  newMember,
  messages,
  cases,
  newMessage,
  mailValidation,
  reply,
  fslAutoRedirectLogin,
  fslCallback,
};

export default Routing;
