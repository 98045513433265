import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useInformationPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(),
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
        paddingBottom: '5px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: '25px',
      },
    },
  })
);
