import { useState, useEffect } from 'react';
import { fetchRequest } from '../api/fetch';

interface IUser {
  name: string;
  features: string[];
  isInternalUser: boolean;
}

function nowAsNumber(): number {
  return new Date().getTime();
}

class UserService {
  private _timestamp: number = 0;

  private _user: Promise<IUser> | null = null;

  public async getUser(): Promise<IUser> {
    const diff = nowAsNumber() - this._timestamp;
    if (diff < 60000) return this._user!;

    this._user = null;

    const response = await fetchRequest('/identity/whoami', 'GET');
    this._user = await response.json();

    return this._user!;
  }
}

const instance = new UserService();

export default function useUser(): IUser | null {
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    (async () => {
      setUser(await instance.getUser());
    })();
  }, []);

  return user ?? null;
}
