import { ButtonProps, Popover, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { FslButton } from '../FslButton/FslButton';
import { useIconButtonStyles } from './IconButton.styles';

interface IIconButtonProps extends ButtonProps {
  withConfirmDialog?: boolean;
}

const IconButton: FunctionComponent<IIconButtonProps> = ({ children, withConfirmDialog, onClick, ...rest }) => {
  const classes = useIconButtonStyles();
  const { translateString } = useTranslation();
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const open = Boolean(popoverAnchor);

  const handleClick = useCallback((event: any) => {
    setPopoverAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setPopoverAnchor(null);
  }, []);

  return (
    <>
      <button onClick={withConfirmDialog ? handleClick : onClick} className={classes.root} {...rest} type="button">
        {children}
      </button>
      {withConfirmDialog ? (
        <Popover
          open={open}
          anchorEl={popoverAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.popover}>
            <Typography variant="body1">{translateString('are_you_sure')}</Typography>
            <div className={classes.popoverContent}>
              <FslButton
                onClick={(e) => {
                  handleClose();
                  if (onClick) {
                    onClick(e);
                  }
                }}
              >
                {translateString('yes')}
              </FslButton>
              <div className={classes.buttonDivider} />
              <FslButton onClick={handleClose}>{translateString('no')}</FslButton>
            </div>
          </div>
        </Popover>
      ) : undefined}
    </>
  );
};

export default IconButton;
