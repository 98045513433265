import React, { createContext, Dispatch, FunctionComponent, useReducer } from 'react';
import _ from 'lodash';
import { INewMemberContextState, NewMemberContextAction } from './newMemberContext.types';
import reducer from './newMemberContext.reducer';

const initialState: INewMemberContextState = {
  employment: undefined,
  information: undefined,
  positions: [{ key: _.uniqueId() }],
  jobTitles: undefined,
  acceptedTermsAndConditions: undefined,
  bypassPositions: false,
};

export const NewMemberContext = createContext<{
  state: INewMemberContextState;
  dispatch: Dispatch<NewMemberContextAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const NewMemberProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <NewMemberContext.Provider value={{ state, dispatch }}>{children}</NewMemberContext.Provider>;
};
