import React, { useCallback, useEffect, useState } from 'react';
import { ISchoolDropDownItem } from './SchoolPicker.types';

export const useData = (
  value: ISchoolDropDownItem | undefined,
  fetchOptions: (query: string) => Promise<ISchoolDropDownItem[]>,
  onChange?: ((changes: ISchoolDropDownItem | undefined) => void) | undefined
) => {
  const [input, setInput] = useState<string>(value?.name ?? '');
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<ISchoolDropDownItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<ISchoolDropDownItem | undefined>();

  useEffect(() => {
    if (value) {
      setInput(value.name);
    } else {
      setInput('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnInputChanged = useCallback(
    (e: React.ChangeEvent<{}>, newValue: string) => {
      // if i is null, e is fired on mount, in which case we want to ignore it
      if (e !== null) {
        setInput(newValue);
      }
    },
    [setInput]
  );

  useEffect(() => {
    const doFetch = async () => {
      const newOptions: ISchoolDropDownItem[] = await fetchOptions(input);
      setOptions(newOptions);
    };
    doFetch();
  }, [input, fetchOptions, onChange, selectedOption]);

  const handleOnChange = useCallback(
    (_: React.ChangeEvent<{}>, v: ISchoolDropDownItem | null) => {
      if (v) {
        setIsOpen(false);
        setSelectedOption(v);
        onChange?.(v);
      }
    },
    [setIsOpen, setSelectedOption, onChange]
  );

  return { input, handleOnInputChanged, isOpen, setIsOpen, options, selectedOption, handleOnChange };
};
