import { Theme } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from '@fsl/fsl.common.npm';
import { MemberEconomyStatus, MemberStatus } from '../../../../api/responseTypes';

export const useBalanceFormatter = (balance: number | undefined, balanceColor: string, translateNumber: (n: number) => string) => {
  const balanceText = useMemo(() => {
    if (balance === undefined) {
      return '';
    }
    return `${translateNumber(balance)} kr`;
  }, [balance, translateNumber]);

  const highlightBalance = useMemo(() => {
    if (balance && balance < 0) {
      return { valueColor: balanceColor };
    }
    return undefined;
  }, [balance, balanceColor]);

  return { balanceText, highlightBalance };
};

export const useMemberStatusFormatter = (status: MemberStatus | MemberEconomyStatus, theme: Theme) => {
  const { translateString } = useTranslation();
  let color = '';
  let text = '';
  switch (status) {
    case 'Exempt': {
      color = theme.customPalette.yellow;
      text = translateString('exempt');
      break;
    }
    case 'Active': {
      color = theme.customPalette.green;
      text = translateString('active');
      break;
    }
    case 'AttentionNeeded': {
      color = theme.customPalette.red;
      text = translateString('attention_needed');
      break;
    }
    case 'Inactive': {
      color = theme.customPalette.red;
      text = translateString('inactive');
      break;
    }
    case 'UnderWaitingPeriod': {
      color = theme.customPalette.yellow;
      text = translateString('waiting_period');
      break;
    }
    default: {
      color = theme.customPalette.grey;
      text = translateString('unknown');
    }
  }

  return { memberStatusColor: color, memberStatusText: text };
};
