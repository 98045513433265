export enum DawaType {
  vejnavn = 'vejnavn',
  adgangsadresse = 'adgangsadresse',
  adresse = 'adresse',
}

export type DawaAutocompleteResult = {
  type: DawaType;
  tekst: string;
  forslagstekst: string;
  caretPos: number;
  data: DawaAdgangsadresse | DawaVejnavn | DawaAdresse;
};

export type DawaAdgangsadresseResult = DawaAutocompleteResult & {
  type: DawaType.adgangsadresse;
  data: DawaAdgangsadresse;
};

export type DawaAdresseResult = DawaAutocompleteResult & {
  type: DawaType.adresse;
  data: DawaAdresse;
};

export type DawaVejnavnResult = DawaAutocompleteResult & {
  type: DawaType.vejnavn;
  data: DawaVejnavn;
};

export type DawaVejnavn = {
  navn: string;
  href: string;
};

export type DawaAdgangsadresse = {
  id: string;
  status?: number;
  darStatus?: number;
  vejKode?: string;
  vejnavn?: string;
  adresseringsVejnavn?: string;
  husnr?: string;
  supplerendeBynavn?: string;
  postnr?: string;
  postnrnavn?: string;
  stormodtagerPostnr?: string;
  stormodtagerPostnrNavn?: string;
  kommunekode?: string;
  x?: number;
  y?: number;
  href?: string;
};

export type DawaAdresse = DawaAdgangsadresse & {
  etage: string;
  dør: string;
};

export type DawaQueryOptions = {
  type?: DawaType;
  startFra?: DawaType;
  q?: string;
  caretpos?: number;
  postnr?: string;
  kommunekode?: string;
  adgangsadresseid?: string;
  multilinje?: boolean;
  supplerendebynavn?: boolean;
  fuzzy?: '';
  id?: string;
  gældende?: boolean;
  geometri?: 'adgangspunkt' | 'vejpunkt';
  side?: number;
  per_side?: number;
};

export type FslAddress = {
  address?: string;
  city?: string;
  postalCode?: string;
  municipality?: string;
  countryCode?: 'DK';
};

export type DawaKommuneOpslagResult = {
  kode: string;
  navn: string;
  regionskode: string;
  href: string;
  region: {
    href: string;
    kode: string;
    navn: string;
  };
};
