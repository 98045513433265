import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const usePositionPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(),
      '& .MuiOutlinedInput-multiline': {
        borderRadius: '17.5px',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: '25px',
      },
    },
    rateSelector: {
      paddingTop: '2px',
      width: '100%',
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    positionSelector: {
      paddingTop: '-5px',
      marginBottom: '20px',
      width: '100%',
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInput-underline:before': {
        bottom: '1px',
      },
    },
    otherTextarea: {
      paddingTop: theme.spacing(),
      [theme.breakpoints.down('xs')]: {
        '& .MuiInputBase-root': {
          padding: '25px',
        },
      },
    },
    addAndRemovePositionSection: {
      '& path': {
        color: theme.customPalette.lightBlue,
      },
    },
    addAndRemovePositionText: {
      marginLeft: '15px',
      fontStyle: 'italic',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiTypography-body1': {
        lineHeight: 'unset',
      },
    },
    contentSeparator: {
      width: '100%',
      borderTop: `2px solid ${theme.customPalette.darkBlue}`,
      marginBottom: '30px',
    },
    workplaceNotInListGrid: {
      marginTop: '-12px',
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '15px',
      },
    },
    firstJobAsTeacherGrid: {
      [theme.breakpoints.down('xs')]: {
        marginTop: '15px',
      },
    },
    schoolPickerGrid: {
      paddingBottom: '0 !important',
      '& img': {
        height: '12px !important',
        width: '12px !important',
      },
    },
    removeCircleIcon: {
      fill: theme.customPalette.red,
    },
    addCircleIcon: {
      fill: theme.customPalette.darkBlue,
    },
    bypassButton: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '15px',
      '& .MuiButton-label': {
        fontWeight: 'normal',
        color: theme.customPalette.darkBlue,
        textTransform: 'none',
      },
      '& .MuiTouchRipple-root': {
        color: theme.customPalette.darkBlue,
      },
    },
  })
);
