import { useTranslation } from '@fsl/fsl.common.npm';
import { useCallback, useContext, useMemo } from 'react';
import { NewMemberContext } from '../../../newMemberContext';
import { EditablePositionData } from '../PositionPage/PositionPage.types';
import { postMembershipRequest } from './AcknowledgePage.actions';
import { mapToApi } from './AcknowledgePage.mapping';

export const useData = (onNext: () => void) => {
  const { translateDate, translateString } = useTranslation();
  const { state } = useContext(NewMemberContext);

  const fullName = useCallback((firstName: string | undefined, lastName: string | undefined) => {
    if (!firstName && !lastName) {
      return undefined;
    }

    return `${firstName ?? ''} ${lastName ?? ''}`.trim();
  }, []);

  const postalCodeAndCity = useCallback((postalCode: string | undefined, city: string | undefined) => {
    if (!postalCode && !city) {
      return undefined;
    }

    return `${postalCode ?? ''} ${city ?? ''}`.trim();
  }, []);

  const co = useCallback((coString: string | undefined) => {
    if (coString) {
      return `C/o ${coString}`;
    }

    return undefined;
  }, []);

  const postRequest = useCallback(async () => {
    if (state.acceptedTermsAndConditions && state.employment && state.information && state.positions) {
      const membershipRequest = mapToApi(state.acceptedTermsAndConditions, state.employment, state.information, state.positions, state.bypassPositions);
      try {
        await postMembershipRequest(membershipRequest);
      } catch (e) {
        return false;
      }
    }

    onNext();
    return true;
  }, [onNext, state.acceptedTermsAndConditions, state.employment, state.information, state.positions, state.bypassPositions]);

  const findPositionText = useCallback((position: { id: string | number; displayValue: string } | undefined, positionText: string | undefined): string => {
    if (position) {
      if (position.id === 1) {
        return positionText ?? '';
      }
      return position.displayValue;
    }

    return '';
  }, []);

  const findPositions = useCallback(
    (positions: Partial<EditablePositionData>[]) => {
      const findRate = (rate: string | undefined): string => {
        if (rate === '2') {
          return translateString('above_percent', { percent: 51 });
        }
        if (rate === '3') {
          return translateString('below_percent', { percent: 50 });
        }
        return '';
      };

      const result: string[] = [];
      positions.forEach((p) => {
        result.push(findPositionText(p.position, p.positionDescription));
        result.push(p.school?.name ?? p.schoolNameNotInList ?? '');
        result.push(p.startDate ? translateDate(p.startDate) : '');
        result.push(findRate(p.employmentRate));
        result.push('');
      });
      return result;
    },
    [translateDate, findPositionText, translateString]
  );

  const employmentText = useMemo((): string => {
    const { employment } = state;

    if (employment?.employmentId === 1) {
      return translateString('in_work');
    }

    if (employment?.employmentId === 4) {
      return translateString('studying');
    }

    return employment?.employmentText ?? '';
  }, [state, translateString]);

  return {
    fullName: fullName(state.information?.firstName, state.information?.lastname),
    postalCodeAndCity: postalCodeAndCity(state.information?.postalCode, state.information?.city),
    co: co(state.information?.co),
    employment: state.employment,
    ssn: state.information?.ssn,
    address: state.information?.address,
    municipality: state?.information?.municipality,
    country: state.information?.country,
    email: state.information?.email,
    phoneNumber: state.information?.phoneNumber,
    positions: state.bypassPositions ? [] : findPositions(state.positions),
    employmentText,
    postRequest,
  };
};
