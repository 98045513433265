import { setupi18n, TranslationProvider, useTranslation } from '@fsl/fsl.common.npm';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';
import { ApplicationInsightsProvider, useApplicationInsights } from './ApplicationInsights';
import { AppPreload } from './AppPreload';
import { userManager, withOidc } from './auth';
import Modal from './components/Modal/Modal';
import { ModalProvider } from './contexts/ModalProvider/ModalProvider';
import ErrorBoundary from './ErrorBoundary';
import ContentSwitch from './layout/ContentSwitch/ContentSwitch';
import Navbar from './layout/Navbar/Navbar';
import NewMembershipPage from './pages/NewMembershipPage/NewMembershipPage';
import Routing from './routing/routing';
import Theme from './theming/theme';
import { useScreenSizeProvider } from './hooks/useScreenSizeProvider';
import { MobileHomeComponent } from './layout/Navbar/MobileDeviceNavbar/MobileDeviceNavbar';
import MailValidationPage from './pages/NewMembershipPage/newMemberFlow/flowPages/MailValidationPage/MailValidationPage';
import { MessageProvider } from './contexts/MessageContext';
import { UserSelectionProvider } from './contexts/UserSelectionContext';
import { FullScreenLoadingComponent } from './components/misc';
import FslAutoRedirectPage from './pages/FslAutoRedirectPage/FslAutoRedirectPage';
import FslCallbackPage from './pages/FslCallback/FslCallbackPage';

const danishTranslation = require('./translations/danish.json');
const englishTranslation = require('./translations/english.json');

setupi18n([danishTranslation, englishTranslation]);

const MemberFlow: FunctionComponent = () => {
  const { screenSize } = useScreenSizeProvider();
  const smallScreen = screenSize === 'SmallScreen';
  return (
    <UserSelectionProvider>
      <MessageProvider>
        <div style={{ display: 'flex' }}>
          <Navbar smallScreen={smallScreen} />
          {!smallScreen && <ContentSwitch homeComponent={MobileHomeComponent} />}
        </div>
      </MessageProvider>
    </UserSelectionProvider>
  );
};

const MemberFlowWithOidc = withOidc(MemberFlow);

const PerformLogout: FunctionComponent = () => {
  useEffect(() => {
    userManager.signoutRedirect();
  }, []);

  return <FullScreenLoadingComponent />;
};

function PortalRouter() {
  return (
    <Switch>
      <Route path={Routing.newMember} component={NewMembershipPage} exact />
      <Route path={Routing.logout} component={PerformLogout} exact />
      <Route
        path={`${Routing.mailValidation}/:id`}
        component={() => (
          <NewMembershipPage>
            <MailValidationPage />
          </NewMembershipPage>
        )}
        exact
      />

      {/* The following routes are so far only for debugging.
  If enabled in production you need to make sure that state is stored in something else than memeory, perhaps local or session storage */}

      {/* <Route path="/nytInformation" component={() => <NewMembershipPage step={1} />} exact />
  <Route path="/nytPosition" component={() => <NewMembershipPage step={2} />} exact />
  <Route path="/godkend" component={() => <NewMembershipPage step={3} />} exact />
  <Route path="/tak" component={() => <NewMembershipPage step={4} />} exact /> */}
      <Route path={Routing.fslAutoRedirectLogin} component={FslAutoRedirectPage} />
      <Route path={Routing.fslCallback} component={FslCallbackPage} />
      <Route path="/" component={MemberFlowWithOidc} />
    </Switch>
  );
}

function App() {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      notistackRef.current?.closeSnackbar(key);
    },
    [notistackRef]
  );

  return (
    <BrowserRouter>
      <ApplicationInsightsProvider>
        <ThemeProvider theme={Theme}>
          <TranslationProvider>
            <SnackbarProvider ref={notistackRef} maxSnack={3} action={(key) => <Button onClick={onClickDismiss(key)}>Luk</Button>}>
              <UseErrorBoundary>
                <ModalProvider>
                  <Modal />
                  <AppPreload App={PortalRouter} />
                </ModalProvider>
              </UseErrorBoundary>
            </SnackbarProvider>
          </TranslationProvider>
        </ThemeProvider>
      </ApplicationInsightsProvider>
    </BrowserRouter>
  );
}

const UseErrorBoundary: FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const { trackException } = useApplicationInsights();
  const { translateString } = useTranslation();
  return (
    <ErrorBoundary
      primaryMessage={translateString('general_primary_error_message')}
      secondaryMesage={translateString('general_secondary_error_message')}
      logException={trackException}
    >
      {children}
    </ErrorBoundary>
  );
};

export default App;
