import React, { FunctionComponent } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en-GB';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { useTranslation } from '@fsl/fsl.common.npm';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import BasicTextField from '../../input/BasicTextField/BasicTextField';
import { useFSLDatePickerStyles } from './FSLDatePicker.styles';

interface IFSLDatePickerProps {
  label: string;
  date: Date | undefined;
  onDateChanged: (d: Date | undefined) => void;
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  disabled?: boolean;
  datepickerProps?: Omit<KeyboardDatePickerProps, 'label' | 'value' | 'disabled' | 'onChange' | 'TextFieldComponent'>;
}

const FSLDatePicker: FunctionComponent<IFSLDatePickerProps> = ({ label, date, onDateChanged, error, helperText, datepickerProps, disabled }): JSX.Element => {
  const { translateDate, language } = useTranslation();
  const classes = useFSLDatePickerStyles();

  return (
    <MuiPickersUtilsProvider locale={language === 'en' ? en : da} utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabled={disabled}
        openTo="year"
        views={['year', 'month', 'date']}
        className={classes.root}
        allowKeyboardControl={false}
        autoOk
        disableToolbar
        value={date}
        label={label}
        cancelLabel=""
        okLabel=""
        inputMode="none"
        onChange={(d: MaterialUiPickersDate) => onDateChanged(d || undefined)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        TextFieldComponent={(props) => (
          <BasicTextField
            {...props}
            value={date ? translateDate(date) : ''}
            error={error}
            helperText={helperText}
            isReadOnly
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {...datepickerProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FSLDatePicker;
