import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useNewMemberFlowStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperIcon: {
      borderRadius: '17.5px',
      height: '22px',
      width: '22px',
      border: `2px solid ${theme.customPalette.red}`,
    },
    stepperIconComplete: {
      backgroundColor: theme.customPalette.red,
    },
    activeStepperText: {
      fontWeight: theme.fontWeight,
    },
    contentHeader: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        fontWeight: theme.fontWeight,
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '50px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '20px',
        paddingLeft: '39.5px',
        paddingRight: '28.5px',
      },
    },
    centerContent: {
      width: '500px',
    },
    mobileComponentWrapper: {
      paddingLeft: '34px',
      paddingRight: '10px',
    },
  })
);
