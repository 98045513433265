import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useMobileDeviceNavbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 37,
    },
    menuList: {
      listStyleType: 'none',
      padding: `${theme.spacing()}px ${theme.spacing()}px 0 ${theme.spacing()}px`,
      color: theme.customPalette.white,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
    },
    doubleListItem: {
      display: 'flex',
      width: '100%',
    },
    tile: {
      width: '100%',
      height: '125px',
      marginBottom: theme.spacing(),
      textDecoration: 'none',
      [theme.breakpoints.up('sm')]: {
        height: '232px',
      },
    },
    tilePadding: {
      padding: '37px 30px',
      overflow: 'hidden',
      height: '100%',
      position: 'relative',
    },
    tileText: {
      fontWeight: theme.fontWeight,
      textTransform: 'uppercase',
      fontSize: '14px',
    },
    tileContent: {
      paddingLeft: theme.spacing(),
    },
    bottomIcons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      '& svg': {
        marginTop: '15px',
        height: '65px',
        width: '85px',
        [theme.breakpoints.up('sm')]: {
          height: '130px',
          width: '179px',
        },
      },
      [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-end',
      },
    },
    playIcon: {
      position: 'absolute',
      right: 10,
      bottom: -10,
      width: 'max-content',
      padding: `0 ${theme.spacing()}px ${theme.spacing()}px 0`,
      '& svg': {
        height: '30px',
        width: '30px',
        opacity: '1',
        '& path': {
          stroke: theme.customPalette.red,
        },
        '& g': {
          stroke: theme.customPalette.red,
        },
      },
    },
  })
);
